import React, { createContext, useState } from 'react';
import axios from 'axios';
import { backendUrl } from '../config';

export const MachineLearningContext = createContext();

export const MachineLearningProvider = ({ children }) => {
    const [bandAudioFeatures, setBandAudioFeatures] = useState(null);
    const [userPlaylistFeatures, setUserPlaylistFeatures] = useState(null);
    const [recommendedTracks, setRecommendedTracks] = useState(null);
    const [recommendedMoodPlaylist, setRecommendedMoodPlaylist] = useState(null);
    const [recommendedCondutorPlaylists, setRecommendedCondutorPlaylists] = useState(null);
    const [isLoading, setIsLoading] = useState(false);


    const fetchBandAudioFeatures = async (bandName, bandSpotifyId) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/band-audio-features/${bandName}/${bandSpotifyId}`);
            setBandAudioFeatures(response.data);
        } catch (error) {
            console.error('Error fetching band audio features:', error);
            setBandAudioFeatures(null);
        }
        setIsLoading(false);
    };

    const fetchUserPlaylistFeatures = async (spotifyUsername) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/user-playlist-tracks-features/${spotifyUsername}`);
            console.log()
            setUserPlaylistFeatures(response.data);
        } catch (error) {
            console.error('Error fetching user playlist features:', error);
            setUserPlaylistFeatures(null);
        }
        setIsLoading(false);
    };

    const fetchRecommendedTracksByDistance = async (bandName, bandSpotifyId, spotifyUsername) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/recommend-similar/${bandName}/${bandSpotifyId}/${spotifyUsername}`);
            setRecommendedTracks(response.data);
            //console.log('fetchRecommendedTracksByDistance', response.data);
            return response.data; // Ensure to return the data here
        } catch (error) {
            console.error('Error fetching recommended tracks:', error);
            setRecommendedTracks(null);
            return null; // Return null or appropriate value indicating failure
        } finally {
            setIsLoading(false);
        }
    };
    

    const fetchRecommendedPlaylistByMood = async (bandName, bandSpotifyId, spotifyUsername, mood) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/recommend-mood/${bandName}/${bandSpotifyId}/${spotifyUsername}/${mood}`);
            setRecommendedMoodPlaylist(response.data);
            console.log('fetchRecommendedPlaylistByMood', response.data);
            return response.data; // Ensure to return the data here
        } catch (error) {
            console.error('Error fetching recommended playlist by mood:', error);
            setRecommendedMoodPlaylist(null);
        }
        setIsLoading(false);
    };


    const fetchRecommendedPlaylistsByCondutor = async (bandName, bandSpotifyId, spotifyUsername, numCondutores) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/recommend-condutor/${bandName}/${bandSpotifyId}/${spotifyUsername}/${numCondutores}`);
            setRecommendedCondutorPlaylists(response.data);
            console.log('fetchRecommendedPlaylistsByCondutor', response.data);
            return response.data; // Ensure to return the data here
        } catch (error) {
            console.error('Error fetching recommended playlists by condutor:', error);
            setRecommendedCondutorPlaylists(null);
        }
        setIsLoading(false);
    };

    return (
        <MachineLearningContext.Provider value={{ 
            bandAudioFeatures, 
            userPlaylistFeatures,
            recommendedTracks,
            recommendedMoodPlaylist,
            recommendedCondutorPlaylists,
            isLoading, 
            fetchBandAudioFeatures,
            fetchUserPlaylistFeatures,
            fetchRecommendedTracksByDistance,
            fetchRecommendedPlaylistByMood,
            fetchRecommendedPlaylistsByCondutor
        }}>
            {children}
        </MachineLearningContext.Provider>
    );
};
