import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { backendUrl } from '../config';


export const ClimateContext = createContext();

export const ClimateProvider = ({ children }) => {
    const [generalClimateData, setGeneralClimateData] = useState(null);
    const [userClimateData, setUserClimateData] = useState(null);
    const [generalEnergyData, setGeneralEnergyData] = useState(null);
    const [userEnergyData, setUserEnergyData] = useState(null);
    const [generalEmotionData, setGeneralEmotionData] = useState(null);
    const [userEmotionData, setUserEmotionData] = useState(null);
    const [generalPopularityData, setGeneralPopularityData] = useState(null);
    const [userPopularityData, setUserPopularityData] = useState(null);
    const [generalWorldTourData, setWorldTourData] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchDataInParallel = async () => {
            setIsLoading(true);
            await Promise.all([
                fetchGeneralClimateData(),
                fetchGeneralEnergyData(),
                fetchGeneralPopularityData(),
                fetchGeneralEmotionData(),
                fetchWorldTourData(),
            ]);
            setIsLoading(false);
        };
    
        fetchDataInParallel();
    }, []);
    

    const fetchGeneralClimateData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/climate-data`);
            setGeneralClimateData(response.data);
        } catch (error) {
            console.error('Error fetching general climate data:', error);
        }
        setIsLoading(false);
    };

    const fetchUserClimateData = async (birthYear) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/user-climate-data?birth_year=${birthYear}`);
            setUserClimateData(response.data);
        } catch (error) {
            console.error('Error fetching user climate data:', error);
        }
        setIsLoading(false);
    };

    const fetchGeneralEnergyData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/energy-data`);
            setGeneralEnergyData(response.data);
        } catch (error) {
            console.error('Error fetching general energy data:', error);
        }
        setIsLoading(false);
    };

    const fetchUserEnergyData = async (birthYear) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/user-energy-data?birth_year=${birthYear}`);
            setUserEnergyData(response.data);
        } catch (error) {
            console.error('Error fetching user energy data:', error);
        }
        setIsLoading(false);
    };

    const fetchGeneralEmotionData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/emotion-data`);
            setGeneralEmotionData(response.data);
        } catch (error) {
            console.error('Error fetching general emotion data:', error);
        }
        setIsLoading(false);
    };

    const fetchUserEmotionData = async (mood) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/user-emotion-data?mood=${mood}`);
            setUserEmotionData(response.data);
        } catch (error) {
            console.error('Error fetching user emotion data:', error);
        }
        setIsLoading(false);
    };

    const fetchGeneralPopularityData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/popularity-data`);
            setGeneralPopularityData(response.data);
        } catch (error) {
            console.error('Error fetching general popularity data:', error);
        }
        setIsLoading(false);
    };

    const fetchUserPopularityData = async (birthYear) => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/user-popularity-data?birth_year=${birthYear}`);
            setUserPopularityData(response.data);
        } catch (error) {
            console.error('Error fetching user popularity data:', error);
        }
        setIsLoading(false);
    };

    const fetchWorldTourData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get(`${backendUrl}/api/world-tour-data`);
            // No need to parse, as response.data should already be an object    
            setWorldTourData(response.data);
        } catch (error) {
            console.error('Error fetching world tour data:', error);
        }
        setIsLoading(false);
    };
      

    return (
        <ClimateContext.Provider value={{ 
            generalClimateData, 
            userClimateData, 
            generalEnergyData, 
            userEnergyData,
            generalEmotionData, 
            userEmotionData,
            generalPopularityData,
            userPopularityData,
            generalWorldTourData,
            isLoading, 
            fetchGeneralClimateData,
            fetchUserClimateData, 
            fetchGeneralEnergyData, 
            fetchUserEnergyData,
            fetchGeneralEmotionData,
            fetchUserEmotionData,
            fetchGeneralPopularityData,
            fetchUserPopularityData,
            fetchWorldTourData,
        }}>
            {children}
        </ClimateContext.Provider>
    );
};
