import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { SpotifyProvider } from './contexts/SpotifyContext';
import { ClimateProvider } from './contexts/ClimateContext';
import { MachineLearningProvider } from './contexts/MachineLearningContext'; 

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <SpotifyProvider>
        <ClimateProvider>
          <MachineLearningProvider>  
            <App />
          </MachineLearningProvider>
        </ClimateProvider>
      </SpotifyProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
