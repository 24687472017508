import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { hexbin as d3Hexbin } from 'd3-hexbin';
import '../styles/graphs/PlaylistsScatter.css';

const PlaylistsScatter = ({ data }) => {
    const features = ['danceability', 'valence', 'speechiness', 'liveness', 'instrumentalness'];
    const svgRefs = useRef(features.map(() => React.createRef()));

    useEffect(() => {
        if (!data) return;
        const dataArray = Array.isArray(data) ? data : Object.values(data);

        features.forEach((feature, i) => generateGraph(dataArray, feature, svgRefs.current[i]));
    }, [data]); // Re-run when data changes

    const generateGraph = (dataArray, feature, svgRef) => {
        const margin = { top: 20, right: 20, bottom: 20, left: 30 }, // Adjust left margin for label
              width = 300 - margin.left - margin.right,
              height = 150 - margin.top - margin.bottom;

        const x = d3.scaleLinear()
            .domain([0, 1]) // Assuming energy and features are normalized between 0 and 1
            .range([margin.left, width - margin.right]);

        const y = d3.scaleLinear()
            .domain([0, 1]) // Assuming features are normalized between 0 and 1
            .range([height - margin.bottom, margin.top]);

        const svgElement = d3.select(svgRef.current);
        svgElement.selectAll("*").remove(); // Clear previous

        const svg = svgElement
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const points = dataArray.map(d => [x(d.energy), y(d[feature])]);

        const hexbinGenerator = d3Hexbin()
            .radius(8) // Adjust based on visual preference
            .extent([[margin.left, margin.top], [width - margin.right, height - margin.bottom]]);

        const bins = hexbinGenerator(points);

        const color = d3.scaleSequential(d3.interpolateInferno)
            .domain([0, d3.max(bins, d => d.length)]); // Adjust color scale based on max bin count

        svg.append("g")
            .selectAll("path")
            .data(bins)
            .join("path")
            .attr("d", hexbinGenerator.hexagon())
            .attr("transform", d => `translate(${d.x}, ${d.y})`)
            .attr("fill", d => color(d.length));

        // Adjusting the position and font-size of the feature name label on the y-axis
        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - margin.left +30) // Move to the left of the axis
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .attr("text-anchor", "middle")
            .style("fill", "black")
            .style("font-size", "10px") // Smaller font size
            .text(feature);

        // Adjusting the position and font-size of the "Energy" label on the x-axis
        svg.append("text")
            .attr("x", width / 2)
            .attr("y", height + margin.bottom - 20)
            .attr("text-anchor", "middle")
            .style("fill", "black")
            .style("font-size", "10px") // Smaller font size
            .text("energy");
    };

    return (
        <div className="graphs-container" style={{ display: 'flex', justifyContent: 'space-around', width: '100%', flexWrap: 'wrap', alignItems: 'flex-end' }}>
            {features.map((feature, index) => (
                <svg ref={svgRefs.current[index]} key={feature} className="playlist-hexbin-plot" />
            ))}
        </div>
    );
};

export default PlaylistsScatter;
