// SmallCard.js
import React from 'react';
import '../styles/SmallCard.css';

const SmallCard = ({ text, onClick, onMouseEnter, onMouseLeave }) => {
  return (
    <div className="small-card" onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {text}
    </div>
  );
};

export default SmallCard;
