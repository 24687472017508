import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const EmotionModal = ({ isOpen, onRequestClose, children, onMoodChange }) => {

    const emotionMapping = {
        "C Major": "Innocently Happy",
        "C Minor": "Innocently Sad, Love-Sick",
        "C# Minor": "Despair, Wailing, Weeping",
        "DB Major": "Grief, Depressive",
        "D Major": "Triumphant, Victorious War-Cries",
        "D Minor": "Serious, Pious, Ruminating",
        "D# Minor": "Deep Distress, Existential Angst",
        "Eb Major": "Cruel, Hard, Yet Full of Devotion",
        "E Major": "Quarrelsome, Boisterous, Incomplete Pleasure",
        "E Minor": "Effeminate, Amorous, Restless",
        "F Major": "Furious, Quick-Tempered, Passing Regret",
        "F Minor": "Obscure, Plaintive, Funereal",
        "F# Major": "Conquering Difficulties, Sighs of Relief",
        "F# Minor": "Gloomy, Passionate Resentment",
        "G Major": "Serious, Magnificent, Fantasy",
        "G Minor": "Discontent, Uneasiness",
        "Ab Major": "Death, Eternity, Judgement",
        "Ab Minor": "Grumbling, Moaning, Wailing",
        "A Major": "Joyful, Pastoral, Declaration of Love",
        "A Minor": "Tender, Plaintive, Pious",
        "Bb Major": "Joyful, Quaint, Cheerful",
        "Bb Minor": "Terrible, the Night, Mocking",
        "B Major": "Harsh, Strong, Wild, Rage",
        "B Minor": "Solitary, Melancholic, Patience"
    };

    const moodKeys = Object.keys(emotionMapping); 

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="modal-overlay"
            className="modal-content"
        >
            <div className="modal-header">
                <button onClick={onRequestClose} className="modal-close-button">&times;</button>
            </div>
            <div>
                <h3>Escolha um Mood</h3>
                    <select onChange={(e) => onMoodChange(emotionMapping[e.target.value])} defaultValue="">
                        <option value="" disabled>Select mood</option>
                        {Object.entries(emotionMapping).map(([key, description], index) => (
                            <option key={index} value={key}>{description}</option>
                        ))}
                    </select>

            </div>
            {children}
        </Modal>
    );
};

export default EmotionModal;
