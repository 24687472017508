import React from 'react';
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../../styles/IconModal.css'; 
import { handleDownload } from '../Downloads';

Modal.setAppElement('#root');

const IconModal = ({ isOpen, onRequestClose, icon, mediaPaths }) => {
  const isLargeGallery = icon === 'Cifras' || icon === 'Movs'; 

  const isVideoObject = (item) => typeof item === 'object' && item.url && item.thumbnail;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Icon Modal"
      overlayClassName="modal-overlay"
      className={`modal-carousel-content ${isLargeGallery ? 'modal-carousel-content-large' : ''}`}
    >
      <div className="carousel-container">
        <Carousel showArrows={true} showThumbs={true}>
          {mediaPaths.map((item, index) => {
            const videoObject = isVideoObject(item);

            return (
              <div key={index} className="carousel-slide">
                {videoObject ? (
                  <div className="carousel-video-thumbnail-container">
                    <ReactPlayer
                      className="carousel-video-thumbnail"
                      url={item.url}
                      light={item.thumbnail}
                      controls={true}
                      width='100%'
                      height='100%'
                    />
                  </div>
                ) : (
                  <>
                    <img 
                      src={item}
                      alt={`Slide ${index + 1}`}
                      className="carousel-media"
                    />
                    <button 
                      className="download-button" 
                      onClick={() => handleDownload(item, index)}
                    >
                      Download
                    </button>
                  </>
                )}
              </div>
            );
          })}
        </Carousel>
      </div>
    </Modal>
  );
};

export default IconModal;
