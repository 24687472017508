import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import { useSpotify } from '../contexts/SpotifyContext';
import { useAuth } from '../contexts/AuthContext';
import useNavigation from '../utils/useNavigation';
import AnimatedTitle from '../effects/TitleAnimation';
import { ClimateContext } from '../contexts/ClimateContext';
import EnergyTooltip from '../utils/tooltip/EnergyTooltip'; 
import EnergyModal from '../utils/modal/EnergyModal'; 
import StreamingServicesModal from '../utils/modal/StreamingServicesModal'; 
import EnergyStrip from '../effects/EnergyStrip'; 
import EnergyStripUser from '../effects/EnergyStripUser'; 
import SmallCard from '../utils/SmallCard';
import SpotifyPlayer from 'react-spotify-web-playback';
import axios from 'axios';
import { backendUrl } from '../config';
import { cardsData, streamingServices } from './constants'; 
import '../styles/components/Musica.css';



const Musica = () => {
  const navigate = useNavigate();
  const { handleFanbaseClick } = useNavigation();
  const { isAuthenticated, jwtToken } = useAuth();
  const { spotifyToken, isSpotifyAuthenticated, setSpotifyToken } = useSpotify();
  const [currentTrackId, setCurrentTrackId] = useState(null);
  const { generalEnergyData, userEnergyData, isLoading, fetchUserEnergyData } = useContext(ClimateContext);
  const [userBirthYear, setUserBirthYear] = useState('');
  const [showUserEnergyModal, setShowUserEnergyModal] = useState(false);
  const [lambeImage, setLambeImage] = useState('');
  const [isStreamingModalOpen, setIsStreamingModalOpen] = useState(false);

  // Inside the Musica component function
  console.log('spotifyToken in Musics:', spotifyToken);
  console.log('isSpotifyAuthenticated in Musica:', isSpotifyAuthenticated);

  
  // New useEffect to rehydrate spotifyToken if it's null
  useEffect(() => {
    if (isSpotifyAuthenticated && !spotifyToken) {
      const storedToken = localStorage.getItem('spotify_token');
      const storedExpiration = parseInt(localStorage.getItem('spotify_token_expiration'), 10);

      if (storedToken && storedExpiration && Date.now() < storedExpiration) {
        setSpotifyToken(storedToken);
        console.log('Spotify token rehydrated from localStorage.');
      } else {
        console.error('Spotify token is missing or expired in localStorage.');
        // Optionally, redirect to login or prompt re-authentication
        navigate('/login');
      }
    }
  }, [isSpotifyAuthenticated, spotifyToken, setSpotifyToken, navigate]);


  useEffect(() => {
    // Fetch random lambe image
    axios.get(`${backendUrl}/api/lambe-image`)
      .then(response => {
          setLambeImage(response.data.imagePath);
      })
      .catch(error => console.log(error));
  }, []);

  const sendPlaybackEventToServer = async (trackId) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    try {
      const response = await axios.post(`${backendUrl}/api/track_play`, { trackId }, {
        headers: { Authorization: `Bearer ${jwtToken}` }
      });
      console.log("Server Response:", response.data);
    } catch (error) {
      console.error("Playback Event Failed:", error.response ? error.response.data : error.message);
    }
  };

  const fetchTrackFeatures = async (trackId, accessToken) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    const url = `https://api.spotify.com/v1/audio-features/${trackId}`;
    const headers = { Authorization: `Bearer ${spotifyToken}` };
    try {
      const res = await axios.get(url, { headers });
      if (res.status === 200) {
        const features = res.data;
        await saveFeaturesToDB(features);
      }
    } catch (error) {
      console.error("Error fetching track features:", error.response ? error.response.data : error.message);
    }
  };

  const saveFeaturesToDB = async (features) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    try {
      await axios.post(`${backendUrl}/api/store_audio_features`, features, {
        headers: { Authorization: `Bearer ${jwtToken}` }
      });
    } catch (error) {
      console.error("Error saving features to DB:", error.response ? error.response.data : error.message);
    }
  };

  const fetchTrackAnalysis = async (trackId, accessToken) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
  
    const url = `https://api.spotify.com/v1/audio-analysis/${trackId}`;
    const headers = { Authorization: `Bearer ${accessToken}` };
    try {
      const res = await axios.get(url, { headers });
      if (res.status === 200) {
        const analysis = res.data;
        await axios.post(`${backendUrl}/api/store_audio_analysis`, analysis, {
          headers: { Authorization: `Bearer ${jwtToken}` } // Corrected to use accessToken
        });
      }
    } catch (error) {
      console.error("Error fetching track analysis:", error.response ? error.response.data : error.message);
    }
  };
  

  useEffect(() => {
    if (currentTrackId && spotifyToken) {
      sendPlaybackEventToServer(currentTrackId)
      .then(() => {
          return fetchTrackFeatures(currentTrackId, spotifyToken);
      })
      .catch(error => {
          console.error("Error in handling track play:", error);
      });
    }
  }, [currentTrackId, spotifyToken]);


  const handlePlayerClick = () => {
    if (!spotifyToken) {
      alert('You must authenticate with Spotify to use this feature.');
    }
  };

  const handleCardClick = (text) => {
    console.log(`Card clicked: ${text}`);    
    if (text === "Ouça") {
      setIsStreamingModalOpen(true);
    } else {
      // Handle other card clicks as needed
    }    
  };

  useEffect(() => {
    document.body.style.backgroundImage = "url('/static/images/background/musica-bg.jpeg')";
    return () => {
      document.body.style.backgroundImage = null;
    };
  }, []);

  const redirectToLogin = () => {
    navigate('/login');
  };

  const handleBirthYearSubmit = async (event) => {
    event.preventDefault();
    await fetchUserEnergyData(userBirthYear);
    setShowUserEnergyModal(!isLoading);  // Only show the modal if not loading
  };

  const spotifyAuthCheck = isSpotifyAuthenticated && spotifyToken;


  return (
    <div>
      <nav id="main-nav">
        <ul>
          <li><Link to="/musica">Música</Link></li>
          <li><Link to="/videos">Vídeos</Link></li>
          <li><Link to="/fotos">Fotos</Link></li>
          <li><Link to="/shows">Shows</Link></li>
          <li><Link to="#" onClick={handleFanbaseClick}>Fan Zone</Link></li>
        </ul>
      </nav>

      {/* Energy Strip and Tooltip */}
      <div id="energyStrip" data-tooltip-id="energyTooltip">
          {generalEnergyData && <EnergyStrip data={generalEnergyData} />}
          {/* Add a ghost div to trigger the tooltip */}
          <div id="energy-tooltip-trigger" style={{ position: 'absolute', bottom: '0', width: '100%', height: '50px', cursor: 'pointer' }}></div>
      </div>

      <EnergyTooltip
          userBirthYear={userBirthYear} 
          setUserBirthYear={setUserBirthYear} 
          onSubmit={handleBirthYearSubmit}  
      />

      {/* {isLoading && <div>Loading...</div>}   */}

      {/* Energy Modal */}
      <EnergyModal 
        isOpen={showUserEnergyModal} 
        onRequestClose={() => setShowUserEnergyModal(false)}
      >
        <h3 className="modal-header-text">Energia Musical — {userBirthYear} até o presente</h3>
        {userEnergyData && <EnergyStripUser data={userEnergyData} />}
      </EnergyModal>
  
      {spotifyAuthCheck ? (
        <div onClick={handlePlayerClick}>
          <SpotifyPlayer
            token={spotifyToken}
            uris={['spotify:artist:3tmyoZJT61aig2cwO7Mc3k']}
            styles={{
              activeColor: '#000',
              bgColor: '#5A89A7',
              color: '#fff',
              loaderColor: '#fff',
              sliderColor: '#1cb954',
              trackArtistColor: '#ccc',
              trackNameColor: '#fff',
            }}
            callback={(state) => {
              if (state.track) {
                setCurrentTrackId(state.track.id);
              }
            }}
          />
        </div>
      ) : (
        <div className="redirectToLogin-container" style={{ "--font-size": "16px" }}>
          <p>Conecte seu Spotify para ouvir o álbum inteiro</p>
          <button onClick={redirectToLogin}>Go to Login</button>
        </div>
      )}
  
      <AnimatedTitle text="Música" />

      {/* Discrete link to Home */}
      <div className="discrete-home-link">
        <Link to="/" title="Home"><i className="fa-solid fa-globe"></i></Link> {/* FontAwesome home icon */}
      </div>
      
      <div style={{ position: 'absolute', bottom: '30px', left: '0', width: '30%' /* Adjust the width as needed */ }}>
        <iframe 
          style={{borderRadius: '12px', opacity: '0.7'}}
          src="https://open.spotify.com/embed/album/1hVcGSmXHHxUtEE2JBjt1y?utm_source=generator" 
          width="50%" 
          height="630" 
          frameBorder="0" 
          allowFullScreen="" 
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
          loading="lazy">
        </iframe>
      </div>

      <div className="small-cards-container-musica">
        {cardsData.map((text, index) => (
          <SmallCard
            key={index}
            text={text}
            onClick={() => handleCardClick(text)}
          />
        ))}
      </div>

      <StreamingServicesModal
        isOpen={isStreamingModalOpen}
        onRequestClose={() => setIsStreamingModalOpen(false)}
        services={streamingServices}
      />

      {/* Render the lambe image */}
      {lambeImage && (
          <div className="lambe-image-container">
            <img src={lambeImage} alt="Lambe Image" className="lambe-image" />
          </div>
        )}
    </div>
  );
};

export default Musica;
