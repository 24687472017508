// EnergyTooltip.js
import React from 'react';
import { Tooltip } from 'react-tooltip';

const EnergyTooltip = ({ userBirthYear, setUserBirthYear, onSubmit }) => {
  return (
    <Tooltip anchorSelect="#energy-tooltip-trigger" clickable place="top">
      <div>
        <h3 className='energy-centered-title'>Energia Musical — 1961 até o presente</h3>
        
        {/* Additional content */}
        <div>
          <p>
            As faixas representam a energia média das músicas em cada ano.
            <br></br>Gradações de vermelho indicam anos com energia maior ou menor.
          </p>
        </div>
        <h3>Insira seu ano de nascimento</h3>
        <form onSubmit={onSubmit}>
          <input
            type="number"
            placeholder="Insira seu ano de nascimento"
            value={userBirthYear}
            onChange={(e) => setUserBirthYear(e.target.value)}
          />
          <button type="submit">Submit</button>
        </form>
        <ul>
            <li>Fonte: Spotify</li>
        </ul>
      </div>
    </Tooltip>
  );
};

export default EnergyTooltip;
