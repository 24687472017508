import React from 'react';
import { Tooltip } from 'react-tooltip';
import '../../styles/Tooltip.css'; // Import the CSS file here

const GlobeTooltip = ({ content }) => {
    return (
      <Tooltip anchorSelect="#tooltip-trigger" clickable place="top">
        <div>
        <h3 className="global-centered-title">World Tour 2024</h3>            <div> 
            <ul>
                <li>'Warming': aumento da temperatura no país, em graus Celsius, desde 1750</li>
                <li>'Hotness': média da popularidade dos artistas, numa escala de 0 a 100</li>
                <li>'Energia': energia média das músicas no país, numa escala de 0 a 1</li>
                <li>'Máxima': artista que ficou como #1 nas paradas por mais semanas</li> 
                <li>'Mínima': artista que permaneceu nas paradas or menos semanas</li>
            </ul> 
            <p className="global-centered-title">Dados: Berkeley Earth, Spotify</p>
            <p className="footnote">
            Método: Dados de clima são coletados desde 1750. Foram apenas consideradas medições cujo valor<br></br>
            de incerteza se encontram abaixo do valor de corte calculado pela média + 2 desvios padrões.<br></br>
            Devido à assimstria da distribuição da incerteza, utilizamos o valor da mediana, segundo o qual o<br></br>
            aquecimento global é de 1.356 graus Celsius, em meados da década de 10.<br></br>     
            </p>
            </div>
        </div>
      </Tooltip>
    );
  };
  
  export default GlobeTooltip;
  
