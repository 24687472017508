import React, { useContext, useState } from 'react';
import { MachineLearningContext } from './MachineLearningContext';
import SmallCard from '../utils/SmallCard'; 


const SuperPlaylists = ({ spotifyUsername, bandName, bandSpotifyId }) => {
    const {
        fetchRecommendedTracksByDistance,
        fetchRecommendedPlaylistByMood,
        fetchRecommendedPlaylistsByCondutor,
    } = useContext(MachineLearningContext);

    const [iframeSrc, setIframeSrc] = useState(''); 
    const [selectedMood, setSelectedMood] = useState('');
    const moodFeatures = {
        'Super Up': 'Musicas mais animadas',
        'Super Down': 'Musicas mais melancolicas',
        'Super Organico': 'Musicas mais acusticas',
        'Super Festivo': 'Musicas mais dançantes',
        'Super Falante': 'Musicas mais faladas do que cantadas',
        'Super Sustentavel': 'Musicas com baixo nivel de energia',
    };
    
    const fetchSpotifyUserId = async () => {
        const token = localStorage.getItem('spotify_token');
        const response = await fetch('https://api.spotify.com/v1/me', {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });
    
        if (!response.ok) {
            console.error('Failed to fetch Spotify user ID');
            return null;
        }
    
        const data = await response.json();
        console.log('Current Spotify User ID:', data.id);
        return data.id;
    };
    
    const fetchUserPlaylists = async (userId) => {
        const token = localStorage.getItem('spotify_token');
        console.log(`Fetching playlists for user ID: ${userId}...`);
        const response = await fetch(`https://api.spotify.com/v1/users/${userId}/playlists`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            console.error("Failed to fetch user playlists");
            return [];
        }

        const data = await response.json();
        console.log(`Fetched ${data.items.length} playlists for user ID: ${userId}`);
        return data.items;
    };

    
    const createPlaylist = async (userId, desiredName = "Super Playlist", description) => {
        console.log(`Attempting to create playlist "${desiredName}"...`);
        const existingPlaylists = await fetchUserPlaylists(userId);
        
        const existingPlaylist = existingPlaylists.find(playlist => playlist.name === desiredName);
        if (existingPlaylist) {
            console.log(`${desiredName} playlist already exists.`);
            return existingPlaylist.id;
        }

        const token = localStorage.getItem('spotify_token');
        const response = await fetch(`https://api.spotify.com/v1/users/${userId}/playlists`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: desiredName,
                description,
                public: false,
            }),
        });

        if (!response.ok) {
            console.error("Failed to create playlist");
            return null;
        }

        const data = await response.json();
        console.log(`${desiredName} playlist created with ID: ${data.id}`);
        return data.id;
    };
    
    

    const addTracksToPlaylist = async (playlistId, tracksUris) => {
        console.log(`Adding tracks to playlist ID: ${playlistId}...`);
        const token = localStorage.getItem('spotify_token');
        const response = await fetch(`https://api.spotify.com/v1/playlists/${playlistId}/tracks`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uris: tracksUris,
            }),
        });

        if (!response.ok) {
            console.error("Failed to add tracks to playlist");
            return;
        }

        console.log(`Tracks added to playlist ID: ${playlistId}`);
    };

    

    const handlePlaylistGeneration = async (type, selectedMood = null) => {
        const userId = await fetchSpotifyUserId();
        if (!userId) {
            console.error('Could not fetch Spotify user ID.');
            return; // Exit if the user ID could not be fetched
        }
        let tracksData;
            let playlistName;
            let description = `Generated by SuperPlaylists for ${type}`;
        
        try {    
            if (type === 'distance') {
                console.log("Fetching tracks by distance...");
                tracksData = await fetchRecommendedTracksByDistance(bandName, bandSpotifyId, spotifyUsername);
                if (tracksData && typeof tracksData === 'object') {
                    const sortedTracks = Object.entries(tracksData)
                        .sort((a, b) => a[1].distance - b[1].distance)
                        .slice(0, 5)
                        .map(entry => entry[1].uri);
                    playlistName = `Super ${spotifyUsername} - Top 5 Pra você`;
                    await createAndPopulatePlaylist(userId, playlistName, description, sortedTracks);
                } else {
                    console.error("Fetched tracks data is empty or not in the expected format:", tracksData);
                }

            } else if (type === 'mood') {
                if (!selectedMood) { // Make sure mood is passed when this type is selected
                    console.error('No mood selected.');
                    return; // Exit if no mood is selected for mood type
                }
                console.log("Fetching playlist by mood...");
                tracksData = await fetchRecommendedPlaylistByMood(bandName, bandSpotifyId, spotifyUsername, selectedMood);
                if (tracksData && Array.isArray(tracksData.playlist)) {
                    playlistName = `${selectedMood}`;
                    const trackUris = tracksData.playlist.map(id => `spotify:track:${id}`);
                    await createAndPopulatePlaylist(userId, playlistName, description, trackUris);
                } else {
                    console.error("Fetched mood playlist data is empty or not in the expected format:", tracksData);
                }

            } else if (type === 'condutor') {
                console.log("Fetching playlists by conductor with a fixed number of 5 clusters...");
                const fixedNumCondutores = 5; // Use a fixed number of clusters
            
                const condutorData = await fetchRecommendedPlaylistsByCondutor(bandName, bandSpotifyId, spotifyUsername, fixedNumCondutores);
                if (condutorData && Array.isArray(condutorData) && condutorData.length > 0) {
                    await Promise.all(condutorData.map(async (trackIds, index) => {
                        const actualClusterName = `Super Cluster ${index + 1}`; // Naming each playlist based on its cluster number
            
                        if (Array.isArray(trackIds) && trackIds.length > 0) {
                            const playlistId = await createPlaylist(userId, actualClusterName, `Playlist for cluster ${index + 1}`);
                            if (playlistId) {
                                // Handle adding tracks in batches if necessary
                                for (let i = 0; i < trackIds.length; i += 100) {
                                    const batch = trackIds.slice(i, i + 100);
                                    await addTracksToPlaylist(playlistId, batch);
                                }
                            } else {
                                console.error(`Failed to create playlist for cluster ${index + 1}`);
                            }
                        } else {
                            console.error(`No tracks found for cluster ${index + 1}`);
                        }
                    }));
                } else {
                    console.error("Fetched conductor playlist data is empty or not in the expected format:", condutorData);
                }
            }
        } catch (error) {
            console.error("Error in playlist generation process:", error);
        }
    };
    
    async function createAndPopulatePlaylist(userId, playlistName, description, trackUris) {
        console.log(`Creating playlist "${playlistName}"...`);
        try {
            const playlistId = await createPlaylist(userId, playlistName, description);
            console.log(`Playlist ID: ${playlistId}`);
            await addTracksToPlaylist(playlistId, trackUris);
            const embedUri = `https://open.spotify.com/embed/playlist/${playlistId}`;
            console.log(`Embed URL: ${embedUri}`);
            setIframeSrc(embedUri);
          } catch (error) {
            console.error("Error during playlist creation or track addition:", error);
          }
    }

       
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', marginTop: '100px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px' }}>
                <SmallCard text="Super You" onClick={() => handlePlaylistGeneration('distance')} />
                <div className="small-card" style={{ padding: '0', overflow: 'hidden' }}>
                <select 
                    value={selectedMood} 
                    onChange={(e) => {
                        const newMood = e.target.value;
                        setSelectedMood(newMood);
                        if (newMood) {
                            handlePlaylistGeneration('mood', newMood); // Now passes the mood directly
                        }
                    }} 
                    style={{
                        appearance: 'none',
                        width: '100%',
                        height: '100%',
                        border: '0',
                        background: 'transparent',
                        padding: '8px 16px',
                        fontFamily: "'Georgia', serif",
                        fontSize: '0.8rem',
                        fontStyle: 'italic',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        textAlign: 'center',
                    }}
                    >
                    <option value="">Super Moods</option>
                    {Object.entries(moodFeatures).map(([mood]) => (
                        <option key={mood} value={mood}>{mood}</option>
                    ))}
                </select>
            </div>
                <SmallCard text="Super Clusters" onClick={() => handlePlaylistGeneration('condutor')} />
            </div>
            {iframeSrc && (
                <div style={{
                    marginTop: '15px', // Adjust this value to move the iframe down as needed
                    width: '25%',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <iframe
                        style={{ borderRadius: '12px', opacity: '0.7' }}
                        src={iframeSrc}
                        width="100%"
                        height="150"
                        frameBorder="0"
                        allowFullScreen=""
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy">
                    </iframe>
                </div>
            )}
        </div>
    );
};

export default SuperPlaylists;