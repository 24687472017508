import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const useNavigation = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const handleFanbaseClick = (event) => {
    event.preventDefault();
    if (!isAuthenticated) {  // Removed the function call
      navigate('/login');
      return;
    }
    navigate('/fanbase');
  };

  return { handleFanbaseClick };
};

export default useNavigation;
