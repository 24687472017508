// ClimateModal.js
import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); 

const ClimateModal = ({ isOpen, onRequestClose, children }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="modal-overlay"
            className="modal-content"
        >
            <div className="modal-header">
                <button onClick={onRequestClose} className="modal-close-button">&times;</button>
            </div>
            {children}
        </Modal>
    );
};

export default ClimateModal;
