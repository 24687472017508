import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import useNavigation from '../utils/useNavigation';
import AnimatedTitle from '../effects/TitleAnimation';
import { ClimateContext } from '../contexts/ClimateContext';
import WorldTourGraph from '../effects/WorldTourGraph';
import GlobeTooltip from '../utils/tooltip/GlobeTooltip';
import TourCard from '../utils/TourCard';
import SmallCard from '../utils/SmallCard';
import StreamingServicesModal from '../utils/modal/StreamingServicesModal'; 
import axios from 'axios';
import { backendUrl } from '../config';
import { streamingServices, cardsData} from './constants'; 
import '../styles/components/Shows.css';
import '../styles/Lambe.css';



const Shows = () => {
  const { handleFanbaseClick } = useNavigation();
  const [lambeImage, setLambeImage] = useState('');
  const {generalWorldTourData, isLoading } = useContext(ClimateContext);
  const [isStreamingModalOpen, setIsStreamingModalOpen] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);


  useEffect(() => {
    document.body.style.backgroundImage = "url('/static/images/background/shows-bg.jpeg')";
  }, []);


  useEffect(() => {
    axios.get(`${backendUrl}/api/lambe-image`)
      .then(response => {
          setLambeImage(response.data.imagePath);
      })
      .catch(error => console.log(error));
  }, []);


  const handleCardClick = (text) => {
    console.log(`Card clicked: ${text}`);    
    if (text === "Ouça") {
      setIsStreamingModalOpen(true);
    } else {
      // Handle other card clicks as needed
    }    
  };

  const graphWidth = 400; // You can adjust this based on your layout


  return (
    <>
      <nav id="main-nav">
        <ul>
          <li><Link to="/musica">Música</Link></li>
          <li><Link to="/videos">Vídeos</Link></li>
          <li><Link to="/fotos">Fotos</Link></li>
          <li><Link to="/shows">Shows</Link></li>
          <li><Link to="#" onClick={handleFanbaseClick}>Fan Zone</Link></li>
        </ul>
      </nav>

      <div className="shows-container">
      
      <div className="unique-shows-class">
        <AnimatedTitle text="Turnê" />
      </div>

      {/* Insert the standalone tour card with hardcoded data */}
      <div className="tour-card-wrapper"> {/* Add a container for positioning */}
          <TourCard
            dateRange="15th Jun 2024"
            event="Lollapalooza SP"
            location="São Paulo, Brasil"
          />
          {/* You can insert more TourCard components here with different data if needed */}
        </div>


        {/* Discrete link to Home */}
      <div className="discrete-home-link">
        <Link to="/" title="Home"><i className="fa-solid fa-globe"></i></Link> {/* FontAwesome home icon */}
      </div>

      <div className="world-tour-graph-container">
            {generalWorldTourData && (
                <WorldTourGraph
                    data={generalWorldTourData}
                    width={graphWidth}
                    position="right-top"
                    setTooltipVisible={setTooltipVisible}
                />
            )}
        </div>

        <GlobeTooltip />

        <div className="small-cards-container-show">
          {cardsData.map((text, index) => (
            <SmallCard
              key={index}
              text={text}
              onClick={() => handleCardClick(text)}
            />
          ))}
        </div>

        <StreamingServicesModal
          isOpen={isStreamingModalOpen}
          onRequestClose={() => setIsStreamingModalOpen(false)}
          services={streamingServices}
        />

        {lambeImage && (
          <div className="lambe-image-container shows-lambe-image-container">
            <img src={lambeImage} alt="Lambe Image" className="lambe-image" />
          </div>
        )}
      </div>
    </>
  );
};

export default Shows;
