import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import useNavigation from '../utils/useNavigation';
import AnimatedTitle from '../effects/TitleAnimation';
import ReactPlayer from 'react-player';
import { ClimateContext } from '../contexts/ClimateContext';
import EmotionGraph from '../effects/EmotionGraph';
import EmotionGraphUser from '../effects/EmotionGraphUser';
import EmotionTooltip from '../utils/tooltip/EmotionTooltip';
import EmotionModal from '../utils/modal/EmotionModal'; 
import StreamingServicesModal from '../utils/modal/StreamingServicesModal'; 
import SmallCard from '../utils/SmallCard';
import axios from 'axios';
import { backendUrl } from '../config';
import { streamingServices, cardsData, videoUrls} from './constants'; 
import '../styles/Lambe.css';
import '../styles/components/Shows.css';
import '../styles/components/Video.css'



const Videos = () => {
    const { handleFanbaseClick } = useNavigation();

    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [playingIndex, setPlayingIndex] = useState(null);
    const [lambeImage, setLambeImage] = useState('');
    const {generalEmotionData, fetchUserEmotionData, userEmotionData, isLoading } = useContext(ClimateContext);
    const [userMood, setUserMood] = useState('');
    const [showUserEmotionModal, setShowUserEmotionModal] = useState(false);
    const [isStreamingModalOpen, setIsStreamingModalOpen] = useState(false);


    useEffect(() => {
        document.body.style.backgroundImage = "url('/static/images/background/videos-bg.jpeg')";
    }, []);


    useEffect(() => {
        axios.get(`${backendUrl}/api/lambe-image`)
          .then(response => {
              setLambeImage(response.data.imagePath);
          })
          .catch(error => console.log(error));
      }, []);


    const handleVideoPlay = (index) => {
        setPlayingIndex(index);
        };
    

    const handleCardClick = (text) => {
            console.log(`Card clicked: ${text}`);    
            if (text === "Ouça") {
              setIsStreamingModalOpen(true);
            } else {
              // Handle other card clicks as needed
            }    
          };

    
    const handleMoodSubmit = async (event) => {
        event.preventDefault();
        if (!userMood) {
          alert('Please select a mood.');
          return;
        }
        await fetchUserEmotionData(userMood);
        setShowUserEmotionModal(true);
      };
    

    const handleMoodChange = async (selectedMood) => {
        setUserMood(selectedMood);
        await fetchUserEmotionData(selectedMood);
        // The state update is handled within fetchUserEmotionData
      };
     
    
    const emotionGraphHeight = 120; // Example height in pixels

      return (
        <div>
            <nav id="main-nav">
                <ul>
                    <li><Link to="/musica">Música</Link></li>
                    <li><Link to="/videos">Vídeos</Link></li>
                    <li><Link to="/fotos">Fotos</Link></li>
                    <li><Link to="/shows">Shows</Link></li>
                    <li><Link to="#" onClick={handleFanbaseClick}>Fan Zone</Link></li>
                </ul>
            </nav>

            <AnimatedTitle text="Vídeos" />
    
            <div className="discrete-home-link">
                <Link to="/" title="Home"><i className="fa-solid fa-globe"></i></Link>
            </div>
    
            <div className="video-wrapper">
                <div className="video-container">
                    <div 
                        className="video" 
                        onClick={() => handleVideoPlay(currentVideoIndex)}
                        style={{ margin: '-240px 0 20px 0' }}
                    >
                        <ReactPlayer
                            url={videoUrls[currentVideoIndex]}
                            controls={true}
                            width='100%'
                            height='auto'
                            playing={currentVideoIndex === playingIndex}
                            onPlay={() => handleVideoPlay(currentVideoIndex)}
                            onEnded={() => setPlayingIndex(null)}
                        />
                    </div>
                </div>
                
                <div 
                    className="swiper-button-prev" 
                    onClick={() => setCurrentVideoIndex((currentVideoIndex - 1 + videoUrls.length) % videoUrls.length)}
                >
                    &#10094;
                </div>
    
                <div 
                    className="swiper-button-next"
                    onClick={() => setCurrentVideoIndex((currentVideoIndex + 1) % videoUrls.length)}
                >
                    &#10095;
                </div>
            </div>
    
            {/* Added Emotion Graph, Tooltip, Modal, and Lambe Image from Shows.js */}
            <div className="emotion-graph-container">
                {generalEmotionData && <EmotionGraph data={generalEmotionData} height={emotionGraphHeight} />}
                <div id="emotion-tooltip-trigger" style={{ position: 'absolute', bottom: '50px', width: '100%', height: '50px', cursor: 'pointer' }}></div>
            </div>
    
            <EmotionTooltip
                userMood={userMood}
                setUserMood={setUserMood}
                onSubmit={handleMoodSubmit}
            />
    
            <EmotionModal 
                isOpen={showUserEmotionModal}
                onRequestClose={() => setShowUserEmotionModal(false)}
                onMoodChange={handleMoodChange}
            >
                <h3 className="modal-header-text">Frequência — {userMood} — de 1921 até o presente</h3>
                {userEmotionData && (
                    <EmotionGraphUser data={userEmotionData} height={emotionGraphHeight} />
                )}
            </EmotionModal>

            <div className="small-cards-container-videos">
                {cardsData.map((text, index) => (
                    <SmallCard
                    key={index}
                    text={text}
                    onClick={() => handleCardClick(text)}
                    />
                ))}
            </div>

            <StreamingServicesModal
                isOpen={isStreamingModalOpen}
                onRequestClose={() => setIsStreamingModalOpen(false)}
                services={streamingServices}
            />
    
            {lambeImage && (
                <div className="lambe-image-container shows-lambe-image-container">
                    <img src={lambeImage} alt="Lambe Image" className="lambe-image" />
                </div>
            )}
    
        </div>
    );
};

export default Videos;
