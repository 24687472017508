import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import '../styles/graphs/Energy.css';

const EnergyStrip = ({ data }) => {
    const ref = useRef();

    useEffect(() => {
        if (data) {
            const svg = d3.select(ref.current);
            svg.selectAll("*").remove(); // Clear previous rendering

            const width = svg.node().getBoundingClientRect().width;
            const height = 50; // Set the height of the strip

            svg.attr('width', width).attr('height', height);
            
            // Create a color scale for energy
            const colorScale = d3.scaleLinear()
                .domain([0, (0 + 0.694) / 2, 0.694])  // Setting the lower bound to 0
                .range(["blue", "white", "red"]);
            // Draw the bars
            svg.selectAll("rect")
                .data(data.mean_energies)
                .enter()
                .append("rect")
                .attr("x", (d, i) => (i * width / data.mean_energies.length))
                .attr("y", 0)
                .attr("width", width / data.mean_energies.length)
                .attr("height", height)
                .attr("fill", d => colorScale(d));
        }
    }, [data]);

    return (
        <svg ref={ref} id="energyStrip" className="energy-strip"></svg>
    );
};

export default EnergyStrip;
