// constants.js

export const bandName = 'me_and_the_plant';

export const bandSpotifyId = '6UnzhXp0nsEarq64GIXjEg';

//export const cardsData = ["Comprar CD", "Download", "Ouça"];
export const cardsData = ["Ouça"];
  
export const cardsGallery = ["Estudio Vitor", "Nas Nuvens", "Estudio Kassin"];

export const iconsGallery = ["Letras", "Cifras", "Naves", "Lambes", "Movs"];

export const streamingServices = [
    {
      name: 'Spotify',
      link: 'https://spotify.link/fJMjUIapADb',
      logo: '/static/images/logos/Spotify.png'
    },
    {
      name: 'Apple',
      link: 'https://music.apple.com/us/artist/me-and-the-plant/553389252',
      logo: '/static/images/logos/Apple.png'
    },
    {
      name: 'Deezer',
      link: 'https://www.deezer.com/br/artist/3279711',
      logo: '/static/images/logos/Deezer.png'
    },
    // Add more services as needed...
  ];

  export const videoUrls = [
    'https://youtube.com/shorts/xfE0LbzCSRM', // Trágico shorts - teaser 1 15"
    'https://youtube.com/shorts/pUPTQCf2bsw', // Trágico shorts - teaser 2 15"
    'https://youtube.com/shorts/zj-o6lzS6GQ', // Trágico shorts - teaser 3 15"
    'https://youtube.com/shorts/oJ4BeVXaWZM', // Trágico shorts - teaser 4 15"
    'https://youtube.com/shorts/VWjL5CWIJOo', // Trágico shorts - teaser 6 30"
    'https://youtube.com/shorts/DEd-97Zpgcc', // Trágico shorts - teaser 5 60"
    'https://youtu.be/aPlaMNyCTHY', // Trágico (animated lryic video)
    // 'https://youtu.be/1mra_xmdWIs', // antares,
    // 'https://youtu.be/15U90w3ORW8', // viagem ao centro da terra,
    // 'https://youtu.be/heUNHHrv1u8', // trágico
    // 'https://youtu.be/jgAtu7YIXgY', // hoje
    // 'https://youtu.be/c-9J1ZRQ1bA', // sertão vai virar marte
    // 'https://youtu.be/5ZEwePAR6ug', // delírio
    // 'https://youtu.be/BvhYRsTsvFs', // guerra e paz
    // 'https://youtu.be/d_8sPNz0x4A', // receita de herói
    // 'https://youtu.be/1w3aH0_WkzA', // soneto do sono
    // 'https://youtu.be/0kXQSZhVD-I', // love loop
    // 'https://vimeo.com/877325476?share=copy',
    // 'https://vimeo.com/876956881?share=copy',
    // 'https://vimeo.com/877325456?share=copy',
    // 'https://vimeo.com/876956911?share=copy',
    // 'https://vimeo.com/876956893?share=copy',
    //'https://youtu.be/4QkPAAAPzUA',
    //'https://youtu.be/61-X8k3SpWs',
    //'https://youtu.be/_yDzADaLMD0?si=e2k0QOpTm8lKxBN3',
    //'https://www.youtube.com/watch?v=nGA11DXtQ9s',
    //'https://youtu.be/8wTyMdSIZJ8?si=ZBccAxH6UhH5gsIS',
  ];


  export const galleries = {
    'Estudio Vitor': [
        '/static/images/na_demos/IMG_6815.jpg',
        '/static/images/na_demos/IMG_6814.jpg',
        '/static/images/na_demos/IMG_4675.jpg',
        '/static/images/na_demos/IMG_2709.jpg',
    ],
    'Nas Nuvens': [
      '/static/images/nas_nuvens/IMG_5727.jpg',
      '/static/images/nas_nuvens/IMG_5731.jpg',
      '/static/images/nas_nuvens/IMG_5506.jpg',
      '/static/images/nas_nuvens/IMG_5537.jpg',
      '/static/images/nas_nuvens/IMG_3591.jpg',
    ],
    'Estudio Kassin': [
      '/static/images/no_kassin/IMG_5782.jpg',
      '/static/images/no_kassin/IMG_5806.jpg',
    ],
    'Letras': [
      '/static/images/letras/01_Antares.png',
      '/static/images/letras/02_Viagem_ao_centro_da_Terra.png',
      '/static/images/letras/03_Tragico.png',
      '/static/images/letras/04_Hoje.png',
      '/static/images/letras/05_Sertao_vai_virar_Marte.png',
      '/static/images/letras/06_Delirio.png',
      '/static/images/letras/07_Guerra_e_Paz.png',
      '/static/images/letras/08_Antiheroi.png',
      '/static/images/letras/09_Soneto_do_Sono.png',
      '/static/images/letras/10_Love_Loop.png',
    ],

    'Cifras':[
      '/static/images/cifras/01_Antares.jpeg',
      '/static/images/cifras/02_Viagem_ao_Centro_da_Terra.jpeg',
      '/static/images/cifras/03_Tragico.jpeg',
      '/static/images/cifras/04_Hoje.jpeg',
      '/static/images/cifras/05_Sertao_Vai_Virar_Marte.jpeg',
      '/static/images/cifras/06_Delirio.jpeg',
      '/static/images/cifras/07_Guerra_e_Paz.jpeg',
      '/static/images/cifras/08_Antiheroi.jpeg',
      '/static/images/cifras/09_Soneto_do_Sono.jpeg',
      '/static/images/cifras/10_Love_Loop.jpeg',
    ],

    'Lambes':[
      '/static/images/lambe/lambe_07.jpeg',
      '/static/images/lambe/lambe_02.jpeg',
      '/static/images/lambe/lambe_04.jpeg',
      '/static/images/lambe/lambe_05.jpeg',
      '/static/images/lambe/lambe_08.jpeg',
      '/static/images/lambe/lambe_11.jpeg',
      '/static/images/lambe/lambe_12.jpeg',
      '/static/images/lambe/lambe_13.jpeg',
      '/static/images/lambe/lambe_14.jpeg',
    ],

    'Naves':[
      '/static/images/condutores/01.jpeg',
      '/static/images/condutores/02.jpeg',
      '/static/images/condutores/03.jpeg',
      '/static/images/condutores/04.jpeg',
      '/static/images/condutores/05.jpeg',
      '/static/images/condutores/06.jpeg',
      '/static/images/condutores/07.jpeg',
      '/static/images/condutores/08.jpeg',
      '/static/images/condutores/09.jpeg',
      '/static/images/condutores/10.jpeg',
      '/static/images/condutores/11.jpeg',
      '/static/images/condutores/12.jpeg',
      '/static/images/condutores/13.jpeg',
      '/static/images/condutores/14.jpeg',
      '/static/images/condutores/15.jpeg',
      '/static/images/condutores/16.jpeg',
      '/static/images/condutores/17.jpeg',
      '/static/images/condutores/18.jpeg',
      '/static/images/condutores/19.jpeg',
      '/static/images/condutores/20.jpeg',
      '/static/images/condutores/21.jpeg',
      '/static/images/condutores/22.jpeg',
      '/static/images/condutores/23.jpeg',
      '/static/images/condutores/24.jpeg',
      '/static/images/condutores/25.jpeg',
      '/static/images/condutores/26.jpeg',
      '/static/images/condutores/27.jpeg',
      '/static/images/condutores/28.jpeg',
      '/static/images/condutores/29.jpeg',
      '/static/images/condutores/30.jpeg',
      '/static/images/condutores/31.jpeg',
    ],
    'Movs': [
      {
        url: '/static/images/movs/nas_nuvens_hoje.mp4',
        thumbnail: '/static/images/movs/thumbnails/drums_hoje.jpg', 
        type: 'video', 
      },
      {
        url: '/static/images/movs/kassin_hoje_piano.mp4',
        thumbnail: '/static/images/movs/thumbnails/cp-70_hoje.jpg', 
        type: 'video', 
      },
      {
        url: '/static/images/movs/kassin_hoje_ebow.mp4',
        thumbnail: '/static/images/movs/thumbnails/gibson_hoje.jpg', 
        type: 'video', 
      },
      {
        url: '/static/images/movs/mix_hoje.mp4',
        thumbnail: '/static/images/movs/thumbnails/mix_hoje.jpg', 
        type: 'video', 
      },
      {
        url: '/static/images/movs/nas_nuvens_delirio_drums.mp4',
        thumbnail: '/static/images/movs/thumbnails/drums_delirio.jpg', 
        type: 'video', 
      },
      {
        url: '/static/images/movs/nas_nuvens_delirio_take.mp4',
        thumbnail: '/static/images/movs/thumbnails/precision_deliro.jpg', 
        type: 'video', 
      },
      {
        url: '/static/images/movs/nas_nuvens_delirio_take2.mp4',
        thumbnail: '/static/images/movs/thumbnails/precision_deliro.jpg', 
        type: 'video', 
      },
      {
        url: '/static/images/movs/nas_nuvens_tragico_baixo.mp4',
        thumbnail: '/static/images/movs/thumbnails/mix_hoje.jpg', 
        type: 'video', 
      },
      {
        url: '/static/images/movs/mix_antiheroi_tape.mp4',
        thumbnail: '/static/images/movs/thumbnails/tape_machine_antiheroi.jpg', 
        type: 'video', 
      },
      {
        url: '/static/images/movs/kassin_soneto_mix.mp4',
        thumbnail: '/static/images/movs/thumbnails/mix_soneto.jpg', 
        type: 'video', 
      },
    ],
  };
  