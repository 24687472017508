import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { feature } from 'topojson-client';
import Versor from './Versor';
import '../styles/graphs/WorldTourGraph.css';



const WorldTourGraph = ({ data, width, position, setTooltipVisible }) => {
  const ref = useRef();
  const countriesList = Object.keys(data).filter(name => data[name].hasOwnProperty('spotify_stats'));
  const tooltipTriggerRef = useRef(null);

  useEffect(() => {
    if (!data || !width) return;

    const height = Math.min(width, 720);
    const canvasElement = ref.current; // Directly use the ref

    canvasElement.width = width;
    canvasElement.height = height;

    const context = canvasElement.getContext("2d");

    const projection = d3.geoOrthographic()
        .fitExtent([[10, 10], [width - 10, height - 10]], { type: "Sphere" });
    const path = d3.geoPath(projection, context);

    // Event listeners for the tooltip
    canvasElement.addEventListener('mouseover', () => setTooltipVisible(true));
    canvasElement.addEventListener('mouseout', () => setTooltipVisible(false));
      
    d3.json('/static/countries-110m.json').then(worldGeoJson => {
          const countries = feature(worldGeoJson, worldGeoJson.objects.countries).features;
          let currentIndex = 0;

          const animateGlobe = () => {
              const country = countries.find(c => c.properties.name === countriesList[currentIndex]);
              if (!country) {
                  currentIndex = (currentIndex + 1) % countriesList.length;
                  setTimeout(animateGlobe, 2000); // Skip to next country if not found
                  return;
              }

              const countryData = data[country.properties.name];
              const centroid = d3.geoCentroid(country);
              const interpolateRotate = d3.geoInterpolate(projection.rotate(), [-centroid[0], -centroid[1]]);

              d3.transition()
                  .duration(1250)
                  .tween("rotate", () => t => {
                      projection.rotate(interpolateRotate(t));
                      context.clearRect(0, 0, width, height);
                      drawGlobe(countries, country, countryData);
                  })
                  .on("end", () => {
                      currentIndex = (currentIndex + 1) % countriesList.length;
                      setTimeout(animateGlobe, 2000); // Adjust timing as needed
                  });
          };

          animateGlobe();
      });

      const drawGlobe = (countries, focusedCountry, countryData) => {
          countries.forEach(country => {
              context.beginPath();
              path(country);
              context.fillStyle = country === focusedCountry ? "red" : "#708090";
              context.fill();
              context.strokeStyle = "#fff";
              context.stroke();
          });

          // Draw the horizon line (globe outline)
          const globeRadius = projection.scale();
          context.beginPath();
          context.arc(width / 2, height / 2, globeRadius, 0, 2 * Math.PI);
          context.strokeStyle = '#fff'; // Color of the outline
          context.lineWidth = 0.25; // Thickness of the outline
          context.stroke();

          if (countryData) {
              displayCountryData(focusedCountry.properties.name, countryData, projection, context, width, height);
          }
      };

      const displayCountryData = (countryName, data, projection, context, width, height) => {
        const { spotify_stats: { hotness, energia, maxima, minima, tempo }, climate_stats: { warming } } = data;
        const coords = projection([-10, 40]); // Example coordinates for display, adjust as needed
    
        // Check if text is going beyond canvas boundaries and adjust
        const textWidth = context.measureText(countryName).width;
        const adjustedX = Math.max(0, Math.min(coords[0], width - textWidth)); // Adjust X position
        const adjustedY = Math.max(20, Math.min(coords[1], height - 20)); // Adjust Y position, 20 is arbitrary padding

        // Display country name in bold
        context.fillStyle = 'black';
        context.font = 'bold 14px Arial';
        context.fillText(countryName, adjustedX, adjustedY - 15);
    
        // Display feature names in bold and values in regular font
        const lineHeight = 15; // Height between lines of text
        let textOffsetY = 0; // Initial offset for text
    
        context.font = 'bold 10px Arial';
        context.fillText("Warming:", coords[0], coords[1] + textOffsetY);
        context.font = '10px Arial';
        context.fillText(`${warming.toFixed(2)}`, coords[0] + 60, coords[1] + textOffsetY);
    
        textOffsetY += lineHeight;
        context.font = 'bold 10px Arial';
        context.fillText("Hotness:", coords[0], coords[1] + textOffsetY);
        context.font = '10px Arial';
        context.fillText(`${hotness.toFixed(2)}`, coords[0] + 60, coords[1] + textOffsetY);
    
        textOffsetY += lineHeight;
        context.font = 'bold 10px Arial';
        context.fillText("Energia:", coords[0], coords[1] + textOffsetY);
        context.font = '10px Arial';
        context.fillText(`${energia.toFixed(2)}`, coords[0] + 60, coords[1] + textOffsetY);
    
        textOffsetY += lineHeight;
        context.font = 'bold 10px Arial';
        context.fillText("Maxima:", coords[0], coords[1] + textOffsetY);
        context.font = '10px Arial';
        context.fillText(maxima, coords[0] + 60, coords[1] + textOffsetY);
    
        textOffsetY += lineHeight;
        context.font = 'bold 10px Arial';
        context.fillText("Minima:", coords[0], coords[1] + textOffsetY);
        context.font = '10px Arial';
        context.fillText(minima, coords[0] + 60, coords[1] + textOffsetY);
    
        textOffsetY += lineHeight;
        context.font = 'bold 10px Arial';
        context.fillText("Tempo:", coords[0], coords[1] + textOffsetY);
        context.font = '10px Arial';
        context.fillText(`${tempo.toFixed(2)}`, coords[0] + 60, coords[1] + textOffsetY);
    };
    

  }, [data, width, setTooltipVisible]);

  let positionClass = "";
  switch (position) {
      case "left":
          positionClass = "move-left";
          break;
      case "right":
          positionClass = "move-right";
          break;
      case "top":
          positionClass = "move-top";
          break;
      case "bottom":
          positionClass = "move-bottom";
          break;
      case "right-top": // New case for right and top
          positionClass = "move-right move-top";
          break;
      default:
          break;
  }

  return (
    <div className="world-tour-graph-container">
      <canvas ref={ref} className={`world-tour-graph ${positionClass}`} id="tooltip-trigger"></canvas>
      {/* Additional element for triggering the tooltip */}
      <div ref={tooltipTriggerRef} style={{ display: 'none' }}></div>
    </div>
  );
};

export default WorldTourGraph;


// Dark Green: #006400
// Royal Blue: #4169E1
// Crimson: #DC143C
// Chocolate: #D2691E
// Indigo: #4B0082
// Sienna: #A0522D
// Slate Gray: #708090
// Sea Green: #2E8B57
// Plum: #DDA0DD
// Coral: #FF7F50
// Forest Green: #228B22
// Dark Olive Green: #556B2F
// Dark Green: #006400
// Hunter Green: #355E3B
// British Racing Green: #004225
