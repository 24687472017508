import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useSpotify } from '../contexts/SpotifyContext';
import AnimatedTitle from '../effects/TitleAnimation';
import { InfinitySpin } from 'react-loader-spinner'; // Import the spinner
import '../styles/components/LoginAndFanbase.css';

const Login = () => {
  const { authenticate, login, isAuthenticated } = useAuth();
  const { isSpotifyAuthenticated, authenticateWithSpotify } = useSpotify();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // State to control spinner visibility
  const [errorMessage, setErrorMessage] = useState(''); // Properly defined here
  const navigate = useNavigate();

  // Redirect if already authenticated with either service
  useEffect(() => {
    if (isAuthenticated || isSpotifyAuthenticated) {
      navigate('/fanbase');
    }
  }, [isAuthenticated, isSpotifyAuthenticated, navigate]);

  useEffect(() => {
    document.body.style.backgroundImage = "url('/static/images/background/login-bg.jpeg')";
    return () => {
      document.body.style.backgroundImage = null;
    };
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    try {
      await login(email, password);
      console.log('Login successful, redirecting...');
      navigate('/fanbase');
    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage('Login failed. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  const handleRegistration = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');
    try {
      await authenticate(email, password);
      console.log('Registration successful, redirecting...');
      navigate('/fanbase');
    } catch (error) {
      console.error('Registration failed:', error);
      setErrorMessage('Registration failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  

  // Also update for Spotify authentication to control loading state
  const handleSpotifyLogin = async () => {
    setLoading(true); // Start loading
    await authenticateWithSpotify(); // Assuming this is an async function
    // The loading state will be set to false via the useEffect hook when isAuthenticated or isSpotifyAuthenticated changes
  };

  return (
    <div className="form-container">
      <AnimatedTitle text="Entre" />
      {loading ? (
        <InfinitySpin
          visible={true}
          width="200"
          color="#964B00"
          ariaLabel="infinity-spin-loading"
        />
      ) : (
        <>
          <button className="spotify-button" onClick={handleSpotifyLogin}>
            Log in with Spotify
          </button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <form>
            <label className="label-text">Email:
              <input 
                type="email"
                className="input-field"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
              />
            </label>
            <label className="label-text">Password:
              <input 
                type="password"
                className="input-field"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
              />
            </label>
            <div className="buttons-container"> {/* Container for buttons */}
              <button onClick={handleLogin} className="submit-button">Log in</button>
              <button onClick={handleRegistration} className="submit-button register-button">Register</button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default Login;
