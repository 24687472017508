// Adjustments to focus only on the distance playlist
import React from 'react';
import { Tooltip } from 'react-tooltip';
import '../../styles/Tooltip.css'; 


const SuperPlaylistsTooltip = ({ generatePlaylist }) => {
  return (
    <Tooltip anchorSelect="#superplaylists-tooltip-trigger" place="top" type="dark" effect="float">
      <div>
        <h2 className='superplaylists-centered-title'>Super Playlists</h2>
        <ul>
          <li><strong>Super You:</strong> Playlist baseada em faixas que são sonicamente próximas às suas playlists.</li>
          <li><strong>Super Moods:</strong> Playlist que correspondem a um humor específico escolhido por você.</li>
          <li><strong>Super Clusters:</strong> Playlists criadas a partir de clusters de músicas similares.</li>
        </ul>
      </div>
    </Tooltip>
  );
};

export default SuperPlaylistsTooltip;

