// ClimateTooltip.js
import React from 'react';
import { Tooltip } from 'react-tooltip';

const ClimateTooltip = ({ userBirthYear, setUserBirthYear, onSubmit }) => {
  return (
    <Tooltip anchorSelect="#tooltip-trigger" clickable place="top">
      <div>
        <h3 className='warming-centered-title'>Temperatura Global — 1850 a 2024</h3>
        
        {/* Additional content */}
        <div>
        <p>
            As faixas representam a temperatura média em cada ano. Gradações
            <br></br>de azul indicam anos mais frios do que a média anterior, e de vermelho 
            <br></br>indicam anos mais quentes.
          </p>
        </div>
        <h3>Insira seu ano de nascimento</h3>
        <form onSubmit={onSubmit}>
          <input
            type="number"
            placeholder="Enter your birth year"
            value={userBirthYear} // Change variable name from userAge to userBirthYear
            onChange={(e) => setUserBirthYear(e.target.value)} // 
          />
          <button type="submit">Submit</button>
        </form>
        <ul>
            <li>Fonte: HadCRUT5 dataset</li>
          </ul>
      </div>
    </Tooltip>
  );
};

export default ClimateTooltip;
