// src/contexts/SpotifyContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { backendUrl } from '../config';

const SpotifyContext = createContext();

export const useSpotify = () => useContext(SpotifyContext);

export const SpotifyProvider = ({ children }) => {
  const storedSpotifyToken = localStorage.getItem('spotify_token');
  const initialSpotifyToken = storedSpotifyToken && storedSpotifyToken !== 'undefined' && storedSpotifyToken !== 'null' ? storedSpotifyToken : null;
  const [spotifyToken, setSpotifyToken] = useState(initialSpotifyToken);
  const [isSpotifyAuthenticated, setIsSpotifyAuthenticated] = useState(!!initialSpotifyToken);

  const handleAuthentication = (accessToken, expiresIn, refreshToken) => {
    console.log('Obtained new Spotify access token:', accessToken);
    setSpotifyToken(accessToken);
    localStorage.setItem('spotify_token', accessToken);
    localStorage.setItem('spotify_refresh_token', refreshToken);
    const expirationTime = Date.now() + (expiresIn * 1000) - (300 * 1000); // milliseconds
    localStorage.setItem('spotify_token_expiration', expirationTime.toString());
    setIsSpotifyAuthenticated(true);
    console.log('Spotify token expiration set to:', expirationTime);
  };

  const authenticateWithSpotify = () => {
    window.location.href = `${backendUrl}/spotify_login`;
  };

  const refreshAccessToken = async (spotifyRefreshToken) => {
    if (!spotifyRefreshToken) {
      console.log('No refresh token available, cannot refresh Spotify token.');
      setIsSpotifyAuthenticated(false);
      return false;
    }
    try {
      const response = await axios.get(`${backendUrl}/refresh_token?refresh_token=${spotifyRefreshToken}`);
      if (response.status === 200) {
        const { access_token, expires_in } = response.data;
        console.log('Refreshed Spotify access token:', access_token); // Log the refreshed token
        handleAuthentication(access_token, expires_in, spotifyRefreshToken);
        return true;
      } else {
        console.log('Failed to refresh Spotify token. Status:', response.status);
        setIsSpotifyAuthenticated(false);
        return false;
      }
    } catch (error) {
      console.error('Error refreshing Spotify token:', error);
      setIsSpotifyAuthenticated(false);
      return false;
    }
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const storedSpotifyToken = localStorage.getItem('spotify_token');
      const storedExpirationTime = parseInt(localStorage.getItem('spotify_token_expiration'), 10); // milliseconds
      const currentTime = Date.now(); // milliseconds
      const bufferTime = 5 * 60 * 1000; // 5 minutes in milliseconds

      console.log('Current Time:', currentTime);
      console.log('Stored Spotify Token:', storedSpotifyToken);
      console.log('Stored Expiration Time:', storedExpirationTime);

      if (!storedSpotifyToken) {
        console.log('No Spotify token found.');
        setIsSpotifyAuthenticated(false);
        return;
      }

      if (!storedExpirationTime || isNaN(storedExpirationTime)) {
        console.log('No valid expiration time found.');
        setIsSpotifyAuthenticated(false);
        return;
      }

      if (currentTime + bufferTime >= storedExpirationTime) {
        console.log('Spotify token has expired or is about to expire.');
        setIsSpotifyAuthenticated(false);
        const refreshToken = localStorage.getItem('spotify_refresh_token');
        if (refreshToken) {
          refreshAccessToken(refreshToken);
        } else {
          console.log("Refresh token missing, prompting for re-authentication.");
          authenticateWithSpotify();
        }
      } else {
        setIsSpotifyAuthenticated(true);
        console.log('Spotify token is still valid.');
      }
    };

    // Check token expiration on mount and set up an interval
    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 5 * 60 * 1000); // Check every 5 minutes

    return () => clearInterval(interval);
  }, []);

  const value = {
    spotifyToken,
    setSpotifyToken,
    isSpotifyAuthenticated,
    authenticateWithSpotify,
    refreshAccessToken,
    handleAuthentication,
  };

  return <SpotifyContext.Provider value={value}>{children}</SpotifyContext.Provider>;
};

export default SpotifyProvider;
