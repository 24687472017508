import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useSpotify } from '../contexts/SpotifyContext';
import AnimatedTitle from '../effects/TitleAnimation';
import useNavigation from '../contexts/useNavigation';
import { MachineLearningContext } from '../contexts/MachineLearningContext';
import SuperPlaylists from '../contexts/SuperPlaylists';
import PlaylistsScatter from '../effects/PlaylistsScatter';
import { backendUrl } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faUserAstronaut, faGuitar, faPaintRoller, faFilm } from '@fortawesome/free-solid-svg-icons';
import IconModal from '../utils/modal/IconModal';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { galleries, bandName, bandSpotifyId } from './constants';
import UserPlaylistsTooltip from '../utils/tooltip/UserPlaylistsTooltip';
import SuperPlaylistsTooltip from '../utils/tooltip/SuperPlaylistsTooltip';
import { InfinitySpin } from 'react-loader-spinner';
import '../styles/components/LoginAndFanbase.css';


// const isSpotifyAuthenticated = () => {
//   const spotifyToken = localStorage.getItem('spotify_token');
//   return spotifyToken !== null;
// };

const Fanbase = () => {
  const [username, setUsername] = useState('');
  const [displayName, setDisplayName] = useState('');
  const { isAuthenticated, refreshAccessToken } = useAuth();
  const { isSpotifyAuthenticated } = useSpotify();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  //const [graphDataLoaded, setGraphDataLoaded] = useState(false);
  const [showPlaylistsScatter, setShowPlaylistsScatter] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const handleFanbaseClick = useNavigation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentIcon, setCurrentIcon] = useState('');
  const [selectedGallery, setSelectedGallery] = useState(null);
  const { fetchBandAudioFeatures, bandAudioFeatures, fetchUserPlaylistFeatures, userPlaylistFeatures } = useContext(MachineLearningContext);  
  const token = localStorage.getItem('jwt_token');

  
  const openModal = (iconName) => {
    console.log("Opening modal for icon:", iconName); 
    setCurrentIcon(iconName);
    setSelectedGallery(iconName); 
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  let authProvider = null;

  if (token) {
    const decoded = jwt_decode(token);
    authProvider = decoded.auth_provider;
  }

  useEffect(() => {
    console.log("isAuthenticated changed:", isAuthenticated);
    console.log("isSpotifyAuthenticated changed:", isSpotifyAuthenticated);
  
    const params = new URLSearchParams(location.search);
    const jwt_token = params.get('jwt_token');
    const jwt_refresh_token = params.get('jwt_refresh_token');
    const spotify_access_token = params.get('spotify_access_token');
    const spotify_refresh_token = params.get('spotify_refresh_token');
    const expires_in = params.get('expires_in');
    const currentTime = Date.now(); // milliseconds
    console.log('Expires In (from URL):', expires_in);
    console.log('Current Time:', currentTime);
  
    // Only store JWT tokens if they are present
    if (jwt_token && jwt_refresh_token) {
      localStorage.setItem('jwt_token', jwt_token);
      console.log('JWT token received and stored:', jwt_token);
      localStorage.setItem('jwt_refresh_token', jwt_refresh_token);
      console.log('JWT refresh token received and stored', jwt_refresh_token);
    }
  
    // Only store Spotify tokens if they are present and valid
    if (spotify_access_token && spotify_refresh_token && expires_in && !isNaN(expires_in)) {
      const expirationTime = currentTime + (parseInt(expires_in, 10) * 1000) - (300 * 1000); // milliseconds
      localStorage.setItem('spotify_token', spotify_access_token);
      localStorage.setItem('spotify_refresh_token', spotify_refresh_token);
      localStorage.setItem('spotify_token_expiration', expirationTime.toString());
      console.log('Spotify token received and stored:', spotify_access_token);
      console.log('Expiration Time:', expirationTime);
    } else if (params.has('spotify_access_token') || params.has('spotify_refresh_token') || params.has('expires_in')) {
      console.error('Invalid expiration time or missing tokens.');
    }
  
    // **New Addition:** Remove query parameters after processing to prevent re-processing
    if (params.has('jwt_token') || params.has('spotify_access_token')) {
      navigate(location.pathname, { replace: true });
    }
  
    const fetchFanbaseData = async () => {
      const token = localStorage.getItem('jwt_token');
      try {
        const response = await axios.get(`${backendUrl}/api/fanbase`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsername(response.data.username);
        setDisplayName(response.data.display_name);
        setIsDataLoaded(true);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          const refreshSuccess = await refreshAccessToken(); // Using refreshAccessToken from your Auth context
          if (refreshSuccess) {
            fetchFanbaseData(); // Retry fetching data after successful refresh
          } else {
            navigate('/login'); // Redirect to login if token refresh failed
          }
        } else {
          console.error('Error fetching fanbase:', error);
          setIsDataLoaded(true); // Consider showing an error message or handling differently
        }
      }
    };
  
    if (isAuthenticated || isSpotifyAuthenticated) {
      fetchFanbaseData();
    } else {
      navigate('/login');
    }
  }, []); // Run only once on component mount
  

  useEffect(() => {
    // Logic to fetch data without affecting the visibility state directly
    if (authProvider === 'spotify' && username) {
      fetchBandAudioFeatures(bandName, bandSpotifyId);
      fetchUserPlaylistFeatures(username)
        .then(() => {
          setLoadingData(false);
          setShowPlaylistsScatter(true); // Ensure visibility is set to true when data is successfully fetched
        })
        .catch(error => {
          console.error("Error fetching data:", error);
          setLoadingData(false);
        });
    }
  }, [authProvider, username]); // Run when authProvider or username changes
  
  
  //console.log('User Playlist Features:', userPlaylistFeatures);
  // console.log('Band Audio Features:', bandAudioFeatures);
  // console.log('User Playlist Features:', userPlaylistFeatures);

  return (
    <div>
      {/* Main navigation - This part will not be blurred */}
      <nav id="main-nav">
        <ul>
          <li><Link to="/musica">Música</Link></li>
          <li><Link to="/videos">Vídeos</Link></li>
          <li><Link to="/fotos">Fotos</Link></li>
          <li><Link to="/shows">Shows</Link></li>
          <li><Link to="#" onClick={handleFanbaseClick}>FanZone</Link></li>
        </ul>
      </nav>

      {/* Unique background container for the Fanbase page */}
      <div className="fanbase-background"></div>
  
      <div className="content-container">
        <div className='title-animation-fanzone'>
          <AnimatedTitle text="Fan Zone" fontSize={18} />
        </div>
  
        {/* Discrete link to Home */}
        <div className="discrete-home-link">
          <Link to="/" title="Home"><i className="fa-solid fa-globe"></i></Link>
        </div>
  
        {/* Icons container */}
        <div className="icons-container">
          <FontAwesomeIcon icon={faPencil} className="faPencil" onClick={() => openModal('Letras')} />
          <FontAwesomeIcon icon={faGuitar} className="fa-guitar" onClick={() => openModal('Cifras')} />
          <FontAwesomeIcon icon={faFilm} className="fa-film" onClick={() => openModal('Movs')} />
          <FontAwesomeIcon icon={faPaintRoller} className="fa-paint-roller" onClick={() => openModal('Lambes')} />
          <FontAwesomeIcon icon={faUserAstronaut} className="fa-user-astronaut" onClick={() => openModal('Naves')} />

          {/* Modal Component */}
          <IconModal 
            isOpen={isModalOpen} 
            onRequestClose={closeModal} 
            icon={currentIcon}
            mediaPaths={galleries[selectedGallery] || []} // Renamed prop to `mediaPaths` to reflect mixed content
            />
        </div>
  
        {/* Greeting Message */}
        <div className="greeting-message">
          {isDataLoaded ? (
            <span>Área exclusiva do {(displayName || username).split('@')[0]}</span>
          ) : (
            <InfinitySpin
              visible={true}
              width="200"
              color="#964B00"
              ariaLabel="loading-indicator"
            />
          )}
        </div>
  
        {loadingData && isSpotifyAuthenticated ? (
          <div className="loading-spinner">
            <InfinitySpin visible={true} width="200" color="#964B00" ariaLabel="loading-data" />
          </div>
        ) : showPlaylistsScatter && (
          <>
            {username && bandName && bandSpotifyId && isSpotifyAuthenticated && (
              <div id="superplaylists-tooltip-trigger" style={{ position: 'relative' }}>
                <SuperPlaylists spotifyUsername={username} bandName={bandName} bandSpotifyId={bandSpotifyId} />
                <SuperPlaylistsTooltip />
              </div>
            )}
            {isSpotifyAuthenticated && (
              <div className="graph-container" id="userplaylists-tooltip-trigger">
                <PlaylistsScatter data={userPlaylistFeatures} />
                <UserPlaylistsTooltip />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Fanbase;