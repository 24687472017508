import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Modal from 'react-modal';
import '../../styles/Carousel.css'; // Import the CSS file here



Modal.setAppElement('#root'); 

const GalleryModal = ({ isOpen, onRequestClose, imagePaths }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            overlayClassName="modal-overlay"
            className="modal-carousel-content"
        >
            <div className="modal-header">
                <button onClick={onRequestClose} className="modal-close-button">&times;</button>
            </div>
            <div className="carousel-container">
                <Carousel>
                    {imagePaths.map((path, index) => (
                        <div key={index}>
                            <img src={path} alt={`Slide ${index + 1}`} className="carousel-image" />
                            {/* <p className="legend">Legend {index + 1}</p> */}
                        </div>
                    ))}
                </Carousel>
            </div>
        </Modal>
    );
};

export default GalleryModal;
