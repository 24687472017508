import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import P5Wrapper from './effects/P5Wrapper';
import Home from './components/Home';
import Login from './components/Login';
import Musica from './components/Musica';
import Videos from './components/Videos';
import Fotos from './components/Fotos';
import Shows from './components/Shows';
import Fanbase from './components/Fanbase';
import { AuthProvider } from './contexts/AuthContext';
import { SpotifyProvider } from './contexts/SpotifyContext'; 
import { ClimateProvider } from './contexts/ClimateContext';
import { MachineLearningProvider } from './contexts/MachineLearningContext';

import './style.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import { useTraceUpdate } from './useTraceUpdate';

const App = (props) => {
  useTraceUpdate(props);
  console.log('App component is rendering');

  useEffect(() => {
    // Define a no-operation (no-op) handler because we are not performing any action
    const noopHandler = () => {};

    // Add passive event listeners to the document
    document.addEventListener('touchstart', noopHandler, { passive: true });
    document.addEventListener('touchmove', noopHandler, { passive: true });

    // Cleanup function to remove the event listeners
    return () => {
      document.removeEventListener('touchstart', noopHandler);
      document.removeEventListener('touchmove', noopHandler);
    };
  }, []);

  return (
    <AuthProvider>
      <SpotifyProvider>
        <ClimateProvider>
          <MachineLearningProvider>  {/* Wrap your Routes with MachineLearningProvider */}
            <Router>
              <div className="page-container">
                <P5Wrapper />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/fanbase" element={<Fanbase />} />
                  <Route path="/musica" element={<Musica />} />
                  <Route path="/videos" element={<Videos />} />
                  <Route path="/fotos" element={<Fotos />} />
                  <Route path="/shows" element={<Shows />} />
                </Routes>
              </div>
            </Router>
          </MachineLearningProvider>
        </ClimateProvider>
      </SpotifyProvider>
    </AuthProvider>
  );
};

export default App;
