// ClimateTooltip.js
import React from 'react';
import { Tooltip } from 'react-tooltip';

const PopularityTooltip = ({ userBirthYear, setUserBirthYear, onSubmit }) => {
  return (
    <Tooltip anchorSelect="#popularity-tooltip-trigger" clickable place="top">
      <div>
        <h3 className='fotos-centered-title'>'Hot' Artists — Popularidade entre 1921 e o presente</h3>
        
        {/* Additional content */}
        <div>
        <p>
            Os pontos representam a popularidade dos artistas em cada ano. Gradações
            <br></br>de azul representam artistas menos populares, e tons de vermelho indicam 
            <br></br>artistas mais populares em relação aos demais.
          </p>
        </div>
        <h3>Insira seu ano de nascimento</h3>
        <form onSubmit={onSubmit}>
          <input
            type="number"
            placeholder="Enter your birth year"
            value={userBirthYear} // Change variable name from userAge to userBirthYear
            onChange={(e) => setUserBirthYear(e.target.value)} // 
          />
          <button type="submit">Submit</button>
        </form>
        <ul>
            <li>Fonte: Spotify</li>
          </ul>
      </div>
    </Tooltip>
  );
};

export default PopularityTooltip;
