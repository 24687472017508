import React from 'react';
import '../styles/TourCard.css'; 

const TourCard = ({ dateRange, event, location }) => {
  return (
    <div className="tour-card">
      <div className="date-range">{dateRange}</div>
      <div className="event-name">{event}</div>
      <div className="location">{location}</div>
      <button className="get-tickets-btn">Ingressos Aqui</button>
    </div>
  );
};

export default TourCard;
