import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import '../styles/graphs/Climate.css';

const ClimateStrip = ({ data }) => {
    const ref = useRef();

    useEffect(() => {
        if (data) {
            const svg = d3.select(ref.current);
            svg.selectAll("*").remove(); // Clear previous rendering

            const width = svg.node().getBoundingClientRect().width;
            const height = 50; // Set the height of the strip

            svg.attr('width', width).attr('height', height);

            // Create a color scale
            const colorScale = d3.scaleLinear()
                .domain([d3.min(data.mean_anomalies), 0, d3.max(data.mean_anomalies)])
                .range(["blue", "white", "red"]);

            // Draw the bars
            svg.selectAll("rect")
                .data(data.mean_anomalies)
                .enter()
                .append("rect")
                .attr("x", (d, i) => (i * width / data.mean_anomalies.length))
                .attr("y", 0)
                .attr("width", width / data.mean_anomalies.length)
                .attr("height", height)
                .attr("fill", d => colorScale(d));
        }
    }, [data]);

    return (
        <svg ref={ref} id="climateStrip" className="climate-strip"></svg>
    );
};

export default ClimateStrip;
