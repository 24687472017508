import React from 'react';
import { Tooltip } from 'react-tooltip';
import '../../styles/Tooltip.css'; 

const UserPlaylistsTooltip = ({ content }) => {
    return (
      <Tooltip anchorSelect="#userplaylists-tooltip-trigger" clickable place="top" className="playlists-graphs">
        <div>
        <h3 className="userplaylists-centered-title">Seus Hábitos de Consumo de Energia Musical</h3>            <div> 
            <ul>
            <li>'Energy': Representa uma medida perceptiva de intensidade e atividade. Tipicamente, faixas energéticas parecem rápidas, altas e barulhentas. Veja abaixo seu o seu 'consumo' de energia é alto.</li>
            <li>'Danceability': Descreve quão adequada uma faixa é para dançar, baseado em uma combinação de elementos musicais incluindo tempo, estabilidade rítmica, força do batimento e regularidade geral.</li>
            <li>'Valence': Faixas com alta valência soam mais positivas (por exemplo, felizes, alegres, eufóricas), enquanto faixas com baixa valência soam mais negativas (por exemplo, tristes, deprimidas, zangadas).</li>
            <li>'Liveliness': Detecta a presença de uma audiência na gravação. Valores mais altos de vivacidade representam uma probabilidade aumentada de que a faixa foi executada ao vivo.</li>
            <li>'Instrumentalness': Prediz se uma faixa não contém vocais. Sons do tipo "Ooh" e "aah" são tratados como instrumentais. Suas faixas mais instrumentais tem mais ou menos energia?</li>
            </ul> 
            <p className="userplaylists-centered-title">Dados: Suas playlists no Spotify</p>
            <p className="footnote">
            Método: Para descobrir seus hábitos de consumo de energia musical, correlacionamos parâmetros do áudio de cada música nas suas playslists.
            No eixo "y" (vertical, com valores maiores para cima) estão os parâmetros 'Danceability','Valence', 'Liveliness'e 'Instrumentalness', 
            <br></br>
            enquando no eixo 'x' (horizontal, com valores maiores para a direita) está sempre a 'Energia'.
            Quando a temperatura do hexagrama é mais 'escura', indica-se uma densidade maior de músicas naquela região do gráfico.
     
            </p>
            </div>
        </div>
      </Tooltip>
    );
  };
  
  export default UserPlaylistsTooltip;
  
