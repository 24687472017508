import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import '../styles/graphs/Climate.css';

const ClimateStripUser = ({ data }) => {
    const ref = useRef();

    useEffect(() => {
        if (data) {
            const svg = d3.select(ref.current);
            svg.selectAll("*").remove(); // Clear previous rendering

            // Use the parent node's width to determine the width of the SVG
            const parentNode = ref.current.parentNode;
            const width = parentNode.clientWidth; // This ensures the SVG width matches the modal width
            const height = 100;  // Adjusted height of the strip

            // Set the width directly to '100%' if the SVG is not filling the modal
            svg.attr('width', '100%').attr('height', height);

            // Create a color scale (same as ClimateStrip)
            const colorScale = d3.scaleLinear()
                .domain([d3.min(data.mean_anomalies), 0, d3.max(data.mean_anomalies)])
                .range(["blue", "white", "red"]);

            // Draw the bars (same as ClimateStrip)
            svg.selectAll("rect")
                .data(data.mean_anomalies)
                .enter()
                .append("rect")
                .attr("x", (d, i) => (i * width / data.mean_anomalies.length))
                .attr("width", width / data.mean_anomalies.length)
                .attr("height", height)
                .attr("fill", d => colorScale(d));
        }
    }, [data]);

    return (
        <svg ref={ref} id="climateStripUser" className="climate-strip-user" style={{ width: '100%', height: '100%' }}></svg>
    );
};

export default ClimateStripUser;
