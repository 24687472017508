import React from 'react';
import Modal from 'react-modal';
import '../../styles/StreamingServices.css';


Modal.setAppElement('#root');

const StreamingServicesModal = ({ isOpen, onRequestClose, services }) => {
    return (
        <Modal 
            isOpen={isOpen} 
            onRequestClose={onRequestClose} 
            className="streaming-services-modal" 
            overlayClassName="modal-overlay"
        >
        <div className="modal-header">
            <div className="modal-title">Super Condutores</div>
            <div className="modal-pointer"></div>
        </div>
        <div className="services-list">
            {services.map((service, index) => (
            <div key={index} className="service-item">
                <img src={service.logo} alt={service.name} />
                <button onClick={() => window.open(service.link, '_blank')}>Play</button>
            </div>
            ))}
        </div>
        </Modal>
    );
};

export default StreamingServicesModal;