import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useSpotify } from './SpotifyContext';  // Import Spotify context

const useNavigation = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { spotifyToken } = useSpotify();  // Use Spotify token to determine auth status

  const handleFanbaseClick = (event) => {
    console.log('Fanbase click handled', { isAuthenticated, spotifyToken });
    event.preventDefault();
    if (!isAuthenticated && !spotifyToken) {
      console.log('Not authenticated, redirecting to /login');
      navigate('/login');
    } else {
      console.log('Authenticated, redirecting to /fanbase');
      navigate('/fanbase');
    }
  };
  

  return { handleFanbaseClick };
};

export default useNavigation;
