// EnergyTooltip.js
import React from 'react';
import { Tooltip } from 'react-tooltip';

const EmotionTooltip = ({ userMood, setUserMood, onSubmit }) => {
  
    const moodOptions = [
        'Despair, Wailing, Weeping',
        'Grief, Depressive', 
        'Death, Eternity, Judgement',
        'Harsh, Strong, Wild, Rage',
        'Deep Distress, Existential Angst',
        'Discontent, Uneasiness',
        'Innocently Happy',
        'Innocently Sad, Love-Sick',
        'Triumphant, Victorious War-Cries',
        'Serious, Pious, Ruminating',
        'Cruel, Hard, Yet Full of Devotion',
        'Quarrelsome, Boisterous, Incomplete Pleasure',
        'Effeminate, Amorous, Restless',
        'Furious, Quick-Tempered, Passing Regret',
        'Obscure, Plaintive, Funereal',
        'Conquering Difficulties, Sighs of Relief',
        'Gloomy, Passionate Resentment',
        'Serious, Magnificent, Fantasy',
        'Grumbling, Moaning, Wailing',
        'Joyful, Pastoral, Declaration of Love',
        'Tender, Plaintive, Pious',
        'Joyful, Quaint, Cheerful',
        'Terrible, the Night, Mocking',
        'Solitary, Melancholic, Patience'
    ];

    return (
    <Tooltip anchorSelect="#emotion-tooltip-trigger" clickable place="top">
      <div>
        <h3 className='videos-centered-title'>Emoções Negativas — 1921 até o presente</h3>
        
        {/* Additional content */}
        <div> 
            <p>Evolução anual do número de músicas com os seguintes moods:</p>
                <ul>
                    <li>1- 'Despair, Wailing, Weeping'</li>
                    <li>2- 'Grief, Depressive', 'Death, Eternity, Judgement'</li>
                    <li>3- 'Harsh, Strong, Wild, Rage'</li>
                    <li>4- 'Deep Distress, Existential Angst'</li> 
                    <li>5- 'Discontent, Uneasiness'</li>
                </ul> 
        </div>
        <h3>Escolha um mood</h3>
        <form onSubmit={onSubmit}>
          <select value={userMood} onChange={(e) => setUserMood(e.target.value)}>
            <option value="">Selecione um mood</option>
            {moodOptions.map((mood, index) => (
              <option key={index} value={mood}>{mood}</option>
            ))}
          </select>
          <button type="submit">Submit</button>
        </form>
            <p>Fonte:Spotify</p>
      </div>
    </Tooltip>
  );
};

export default EmotionTooltip;
