import React, { useRef, useEffect } from 'react';
import p5 from 'p5';

const AnimatedTitle = ({ text, fontSize = 28 }) => { // Add fontSize prop with a default value
  const p5Ref = useRef();

  useEffect(() => {
    const sketch = (p) => {
      let font;

      p.preload = function() {
        font = p.loadFont('/fonts/RansomRegular.otf');
      };

      p.setup = function() {
        p.createCanvas(200, 120, p.WEBGL); 
        p.textFont(font);
        p.textSize(fontSize); // Use the fontSize prop
        p.textAlign(p.CENTER, p.CENTER);
      };

      p.draw = function() {
        p.clear();
        p.rotateY(p.frameCount / 200);
        p.fill(255);
        p.text(text, 0, 0);
      };
    };

    const p5Instance = new p5(sketch, p5Ref.current);

    return () => {
      p5Instance.remove();
    };
  }, [text, fontSize]); // Include fontSize in the dependency array

  return <div ref={p5Ref}></div>;
};

export default AnimatedTitle;
