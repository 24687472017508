import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import '../styles/graphs/Energy.css';

const EnergyStripUser = ({ data }) => {
    const ref = useRef();

    useEffect(() => {
        if (data) {
            const svg = d3.select(ref.current);
            svg.selectAll("*").remove(); // Clear previous rendering

            const parentNode = ref.current.parentNode;
            const width = parentNode.clientWidth;
            const height = 100;

            svg.attr('width', '100%').attr('height', height);

            console.log("Min energy:", d3.min(data.mean_energies));
            console.log("Max energy:", d3.max(data.mean_energies));
            // Create a color scale for energy (same as EnergyStrip)
            const colorScale = d3.scaleLinear()
                .domain([0, (0 + 0.694) / 2, 0.694])  // Setting the lower bound to 0
                .range(["blue", "white", "red"]);
            // Draw the bars (same as EnergyStrip)
            svg.selectAll("rect")
                .data(data.mean_energies)
                .enter()
                .append("rect")
                .attr("x", (d, i) => (i * width / data.mean_energies.length))
                .attr("width", width / data.mean_energies.length)
                .attr("height", height)
                .attr("fill", d => colorScale(d));
        }
    }, [data]);

    return (
        <svg ref={ref} id="energyStripUser" className="energy-strip-user" style={{ width: '100%', height: '100%' }}></svg>
    );
};

export default EnergyStripUser;
