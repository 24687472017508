import React, { useEffect } from 'react';
import p5 from 'p5';

const P5Wrapper = () => {
  useEffect(() => {

    // Remove any existing p5 canvas to avoid duplication
    const existingCanvas = document.getElementById('unique-p5-instance');
    if (existingCanvas) {
      existingCanvas.remove();
    }

    const sketch = (p) => {
      let myFont;
      let yPos;
      let amplitude = 10;
      let frequency = 0.01;

      p.preload = () => {
        myFont = p.loadFont('/fonts/Spac3.ttf');
      };

      p.setup = () => {
        const cnv = p.createCanvas(p.windowWidth, p.windowHeight * 0.1, p.WEBGL);
        cnv.id('unique-p5-instance');
        cnv.parent('p5-container');
        p.textFont(myFont);
        p.textSize(48);
        p.textAlign(p.CENTER, p.CENTER);
        yPos = 0;
      };

      p.draw = () => {
        p.background(90, 137, 167);
        p.translate(0, 0, 0);
        yPos = amplitude * p.sin(p.frameCount * frequency);
        p.fill(255);
        p.text('super condutores', 0, yPos);
      };

      p.windowResized = () => {
        p.resizeCanvas(p.windowWidth, p.windowHeight * 0.1);
      };
    };

    const myP5 = new p5(sketch);

    // Cleanup function to remove sketch on component unmount
    return () => {
      myP5.remove();
    };
  }, []);

  return <div id="p5-container"></div>;
};

export default P5Wrapper;
