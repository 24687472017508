import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useNavigation from '../utils/useNavigation';
import AnimatedTitle from '../effects/TitleAnimation';
import { ClimateContext } from '../contexts/ClimateContext';
import PopularityGraph from '../effects/PopularityGraph';
import PopularityGraphUser from '../effects/PopularityGraphUser';
import PopularityTooltip from '../utils/tooltip/PopularityTooltip';
import GalleryModal from '../utils/modal/GalleryModal';
import PopularityModal from '../utils/modal/PopularityModal';
import StreamingServicesModal from '../utils/modal/StreamingServicesModal'; 
import SmallCard from '../utils/SmallCard';
import axios from 'axios';
import { backendUrl } from '../config';
import { streamingServices, cardsData, cardsGallery, galleries } from './constants'; 
import { InfinitySpin } from 'react-loader-spinner';
import '../styles/Lambe.css';
import '../styles/components/Fotos.css'; 
import '../styles/components/Home.css'; 
import '../styles/Carousel.css'; 




const Fotos = () => {
  const { handleFanbaseClick } = useNavigation();
  const [selectedGallery, setSelectedGallery] = useState(null);
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false);
  const {generalPopularityData, userPopularityData, isLoading, fetchGeneralPopularityData, fetchUserPopularityData} = useContext(ClimateContext);
  const [showPopularityGraph, setShowPopularityGraph] = useState(false);
  const [userBirthYear, setUserBirthYear] = useState('');
  const [showUserPopularityModal, setShowUserPopularityModal] = useState(false);
  const [isStreamingModalOpen, setIsStreamingModalOpen] = useState(false);
  const [lambeImage, setLambeImage] = useState('');


  useEffect(() => {
    if (!isLoading && generalPopularityData) {
      // Optionally, you can add a delay here using setTimeout if you want to wait a bit more after data is loaded
      setTimeout(() => setShowPopularityGraph(true), 500); // Delay in milliseconds
    }
  }, [isLoading, generalPopularityData]);


  useEffect(() => {
    document.body.style.backgroundImage = "url('/static/images/background/fotos-bg.jpeg')";
    axios.get(`${backendUrl}/api/lambe-image`)
      .then(response => {
          setLambeImage(response.data.imagePath);
      })
      .catch(error => console.log(error));
  }, []);


  const handleGalleryClick = (galleryName) => {
    console.log(`Gallery Clicked: ${galleryName}`);
    setSelectedGallery(galleryName);
    setIsGalleryModalOpen(true);
  };
  

  const handleCardClick = (text) => {
    console.log(`Card clicked: ${text}`);    
    if (text === "Ouça") {
      setIsStreamingModalOpen(true);
    } else {
      // Handle other card clicks as needed
    }    
  };

  const handleBirthYearSubmit = async (event) => {
    event.preventDefault();
    await fetchUserPopularityData(userBirthYear);
    setShowUserPopularityModal(!isLoading);
  };

  
  return (
    <div className="fotos-container">
      {/* Navigation */}
      <nav id="main-nav">
        <ul>
          <li><Link to="/musica">Música</Link></li>
          <li><Link to="/videos">Vídeos</Link></li>
          <li><Link to="/fotos">Fotos</Link></li>
          <li><Link to="/shows">Shows</Link></li>
          <li><Link to="#" onClick={handleFanbaseClick}>Fan Zone</Link></li>
        </ul>
      </nav>
  
      {/* Animated Title */}
      <AnimatedTitle text="Fotos" />
  
      {/* Gallery Cards Container */}
      <div className="gallery-cards-container">
        {cardsGallery.map((galleryName, index) => (
          <SmallCard
            key={index}
            text={galleryName}
            onClick={() => handleGalleryClick(galleryName)}
          />
        ))}
      </div>
  
      {/* Gallery Modal */}
      <GalleryModal
        isOpen={isGalleryModalOpen}
        onRequestClose={() => setIsGalleryModalOpen(false)}
        imagePaths={selectedGallery ? galleries[selectedGallery] : []}
      />
  
      {/* Discrete link to Home */}
      <div className="discrete-home-link">
        <Link to="/" title="Home"><i className="fa-solid fa-globe"></i></Link>
      </div>
  
      <div id="popularity-graph-container">
        {isLoading ? (
          <div className="spinner-container">
            <InfinitySpin 
              width="200" 
              color="#964B00" 
              ariaLabel="loading-popularity-data" 
            />
          </div>
        ) : showPopularityGraph ? (
          <>
            <div id="popularity-tooltip-trigger">
              <PopularityGraph data={generalPopularityData} />
            </div>
            <PopularityTooltip
              userBirthYear={userBirthYear} 
              setUserBirthYear={setUserBirthYear} 
              onSubmit={handleBirthYearSubmit}
            />
          </>
        ) : null}
      </div>
  
      {/* Popularity Modal */}
      <PopularityModal
        isOpen={showUserPopularityModal}
        onRequestClose={() => setShowUserPopularityModal(false)}
      >
        <h3 className="modal-header-text">'Hot' Artists — Popularidade — de {userBirthYear} até hoje</h3>
        {userPopularityData && <PopularityGraphUser data={userPopularityData} />}
      </PopularityModal>
  
      {/* Small Cards Container */}
      <div className="small-cards-container">
        {cardsData.map((text, index) => (
          <SmallCard
            key={index}
            text={text}
            onClick={() => handleCardClick(text)}
          />
        ))}
      </div>
  
      {/* Streaming Services Modal */}
      <StreamingServicesModal
        isOpen={isStreamingModalOpen}
        onRequestClose={() => setIsStreamingModalOpen(false)}
        services={streamingServices}
      />
  
      {/* Render the Lambe Image */}
      {lambeImage && (
        <div className="lambe-image-container fotos-lambe-image-container">
          <img src={lambeImage} alt="Lambe Image" className="lambe-image" />
        </div>
      )}
    </div>
  );
};

export default Fotos;
