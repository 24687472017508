import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { backendUrl } from '../config';
import jwt_decode from 'jwt-decode';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwt_token'));
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('jwt_refresh_token'));
  const [authProvider, setAuthProvider] = useState(null); 

  const updateAuthProvider = (token) => {
    try {
      const decoded = jwt_decode(token);
      setAuthProvider(decoded.auth_provider);
    } catch (decodeError) {
      //console.error('Error decoding JWT for auth provider:', decodeError);
      setAuthProvider(null);
    }
  };

  const authenticate = async (email, password) => {
    console.log('Authenticate function called with:', email, password);
    try {
        const res = await axios.post(`${backendUrl}/api/authenticate`, { email, password });
        if (res.status === 200) {
            const { jwt_token, jwt_refresh_token } = res.data;
            //console.log('Access token:', jwt_token);
            //console.log('Refresh token:', jwt_refresh_token);
            localStorage.setItem('jwt_token', jwt_token);
            localStorage.setItem('jwt_refresh_token', jwt_refresh_token);
            //console.log('Just stored JWT token:', localStorage.getItem('jwt_token'));
            //console.log('Just stored Refresh Token:', localStorage.getItem('jwt_refresh_token'));
            setRefreshToken(jwt_refresh_token);
            setUsername(jwt_decode(jwt_token).username);
            setIsAuthenticated(true);
            setJwtToken(jwt_token);
            updateAuthProvider(jwt_token);
            //console.log('Authentication successful, tokens stored:', jwt_token, jwt_refresh_token);
            return true;
        }
    } catch (error) {
        console.error('Authentication failed:', error);
        return false;
    }
  };

  const login = async (email, password) => {
    console.log('Login function called with:', email, password);
    try {
        const res = await axios.post(`${backendUrl}/api/login`, { email, password });
        if (res.status === 200) {
            const { jwt_token, jwt_refresh_token } = res.data;
            localStorage.setItem('jwt_token', jwt_token);
            localStorage.setItem('jwt_refresh_token', jwt_refresh_token);
            setRefreshToken(jwt_refresh_token);
            setUsername(jwt_decode(jwt_token).username);
            setIsAuthenticated(true);
            setJwtToken(jwt_token);
            console.log('Login successful, tokens stored.');
            return true;
        }
    } catch (error) {
        console.error('Login failed:', error.response ? error.response.data.message : error.message);
        return false;
    }
};



  const refreshAccessToken = async () => {
    console.log('Begin refreshAccessToken function');
    const storedRefreshToken = localStorage.getItem('jwt_refresh_token');
    if (!storedRefreshToken) {
      //console.error('JWT refresh token is null or undefined');
      setIsAuthenticated(false);
      setJwtToken(null);
      return;
    }
    console.log('Attempting to refresh JWT token with refresh token:', storedRefreshToken);
    try {
      const res = await axios.post(`${backendUrl}/refresh_token_for_website`, {}, {
        headers: { Authorization: `Bearer ${storedRefreshToken}` }
      });
      //console.log('Response received from refresh token endpoint:', res);
  
      if (res.status === 200) {
        //console.log('Attempting to set new access token');
        const { jwt_token } = res.data;
        localStorage.setItem('jwt_token', jwt_token);
  
        //console.log('Attempting to decode new access token');
        const decoded = jwt_decode(jwt_token);
  
        console.log('Attempting to update state with new access token');
        setJwtToken(jwt_token);
        setUsername(decoded.username);
        setIsAuthenticated(true);
        // Update the auth_provider in the state
        updateAuthProvider(jwt_token);
  
        //console.log('JWT token successfully refreshed:', jwt_token);
      } else {
        setIsAuthenticated(false);
        console.error('Failed to refresh access token: status code', res.status);
      }
    } catch (error) {
      setIsAuthenticated(false);
      console.error('Error occurred while refreshing access token:', error);
    }
  };

  useEffect(() => {
    let refreshTimeoutId;
  
    const updateAuthenticationStatus = async () => {
      const storedAccessToken = localStorage.getItem('jwt_token');
      if (storedAccessToken) {
        const decoded = jwt_decode(storedAccessToken);
        const currentTime = Date.now() / 1000;
        const timeLeftBeforeExpiry = decoded.exp - currentTime;
  
        if (timeLeftBeforeExpiry > 0) {
          setIsAuthenticated(true);
          setUsername(decoded.username);
          setJwtToken(storedAccessToken);
          updateAuthProvider(storedAccessToken);
  
          // Schedule a refresh just before the token expires
          const refreshAdvanceTime = 60; // Refresh 1 minute before expiry
          const refreshTimeout = (timeLeftBeforeExpiry - refreshAdvanceTime) * 1000;
          refreshTimeoutId = setTimeout(refreshAccessToken, refreshTimeout);
        } else {
          setIsAuthenticated(false);
          await refreshAccessToken();
        }
      } else {
        setIsAuthenticated(false);
      }
    };
  
    updateAuthenticationStatus();
  
    // Cleanup: Clear the timeout when the component unmounts or the jwtToken changes
    return () => clearTimeout(refreshTimeoutId);
  }, [jwtToken, refreshAccessToken]);

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    username,
    setUsername,
    jwtToken,
    setJwtToken,
    refreshToken,
    setRefreshToken,
    authenticate,
    login,
    refreshAccessToken,
    authProvider,
    setAuthProvider, 
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
