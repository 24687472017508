import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import '../styles/graphs/Emotion.css';

const EmotionGraph = ({ data, height }) => {
    const ref = useRef();

    // Memoize the transformed data
    const transformedData = useMemo(() => {
        if (!data) return [];

        return Object.entries(data).map(([year, emotions]) => {
            const emotionCounts = {};
            Object.keys(emotions).forEach(emotion => {
                emotionCounts[emotion] = emotions[emotion].count;
            });
            return { year: parseInt(year), ...emotionCounts };
        });
    }, [data]);

    useEffect(() => {
        if (transformedData.length === 0) return;

        const svg = d3.select(ref.current);
        svg.selectAll("*").remove();

        const parentNode = ref.current.parentNode;
        const width = parentNode.clientWidth;
        svg.attr('width', '100%').attr('height', height);

        const stack = d3.stack()
            .keys(Object.keys(transformedData[0]).filter(key => key !== 'year'));

        const layers = stack(transformedData);

        const xScale = d3.scaleLinear()
            .domain(d3.extent(transformedData, d => d.year))
            .range([0, width]);

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(layers, layer => d3.max(layer, d => d[1]))])
            .range([height, 0]);

        const area = d3.area()
            .x(d => xScale(d.data.year))
            .y0(d => yScale(d[0]))
            .y1(d => yScale(d[1]));

        svg.selectAll(".layer")
            .data(layers)
            .enter().append("path")
            .attr("class", "layer")
            .attr("d", area)
            .attr("fill", (d, i) => {
                const year = transformedData[i].year;
                const emotion = d.key;
                return data[year][emotion].color;
            });

        svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(xScale));

        svg.append("g")
            .call(d3.axisLeft(yScale));

    }, [transformedData, height]); // Dependency on transformedData now

    return <svg ref={ref} className="emotion-graph" style={{ height: `${height}px` }}></svg>;
};

export default EmotionGraph;
