import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import '../styles/graphs/Popularity.css';

const PopularityGraph = ({ data }) => {
    const d3Container = useRef(null);

    // Memoize the transformed data
    const transformedData = useMemo(() => {
        return data && data.normalized_popularities && data.years
            ? data.years.map((year, index) => ({
                  date: new Date(year),
                  value: data.normalized_popularities[index]
              }))
            : [];
    }, [data]);

    useEffect(() => {
        if (transformedData.length > 0 && d3Container.current) {
            // Get the width of the container
            const containerWidth = d3Container.current.clientWidth;

            // Set dimensions and scales
            const width = containerWidth;
            const height = 150; // Adjust height as needed
            const marginTop = 20;
            const marginRight = 30;
            const marginBottom = 30;
            const marginLeft = 40;

            const x = d3.scaleUtc()
                .domain(d3.extent(transformedData, d => d.date))
                .range([marginLeft, width - marginRight]);

            const y = d3.scaleLinear()
                .domain(d3.extent(transformedData, d => d.value)).nice()
                .range([height - marginBottom, marginTop]);

            const max = d3.max(transformedData, d => Math.abs(d.value));

            // Create a symmetric diverging color scale.
            const color = d3.scaleSequential()
                .domain([max, -max])
                .interpolator(d3.interpolateRdBu);

            // Clear previous SVG elements
            d3.select(d3Container.current).selectAll("*").remove();

            const svg = d3.select(d3Container.current)
                .attr("width", width)
                .attr("height", height);

            // Add background color
            svg.append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("fill", "#a9a9a9"); // Change the color as needed

            // X-axis
            svg.append("g")
                .attr("transform", `translate(0,${height - marginBottom})`)
                .call(d3.axisBottom(x).ticks(width / 80))
                .call(g => g.select(".domain").remove());

            svg.append("text")
                .attr("x", marginLeft) // Position the text at the left margin
                .attr("y", marginTop) // Position just above the top margin
                .attr("fill", "black") // Text color
                .style("font-size", "10px") // Smaller font size
                .text("Anomaly (°C)"); // The text content

            // Y-axis
            const yAxis = svg.append("g")
                .attr("transform", `translate(${marginLeft},0)`)
                .call(d3.axisLeft(y).ticks(null, "+"))
                .call(g => g.select(".domain").remove());

            // Extend tick lines across the graph
            yAxis.selectAll(".tick line")
                .clone()
                .attr("x2", width - marginLeft - marginRight)
                .attr("stroke-opacity", 0.1);

            // Check if y-axis includes zero and add a bold line if it does
            if (y.domain()[0] <= 0 && y.domain()[1] >= 0) {
                svg.append("line")
                    .attr("x1", marginLeft)
                    .attr("x2", width - marginRight)
                    .attr("y1", y(0))
                    .attr("y2", y(0))
                    .attr("stroke", "black")
                    .attr("stroke-width", "2");
            }

            // Data points
            svg.append("g")
                .attr("stroke", "#000")
                .attr("stroke-opacity", 0.2)
                .selectAll("circle")
                .data(transformedData)
                .join("circle")
                    .attr("cx", d => x(d.date))
                    .attr("cy", d => y(d.value))
                    .attr("fill", d => color(d.value))
                    .attr("r", 2.5);
        }
    }, [transformedData]); // Dependency on transformedData

    return <svg ref={d3Container} className="popularity-graph" />;
};

export default PopularityGraph;
